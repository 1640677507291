import { styled } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Style } from '@mui/icons-material';


export const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme, variant }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    backgroundColor: 'rgba(255, 255, 255, 0.45)',
    margin: theme.spacing(0.0),
    border: 0,
    '&.Mui-selected': {
      backgroundColor: '#1976d2', // Selected background color
      color: '#ffffff', // Selected text/icon color
      '&:hover': {
        backgroundColor: '#1152f93', // Hover color for selected
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: '32px,0px,0px.0px',
    },
    '&:first-of-type': {
      borderRadius: '32px,0px,0px.0px',
    },
    ...(variant === 'spaced' && theme.spacedCustomToggleButtonGroup),
    ...(variant === 'lined' && theme.linedCustomToggleButtonGroup),
  },
}));

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    color: '#1976d2', // Default icon color
    '&:hover': {
      backgroundColor: 'rgba(25, 118, 210, 0.45)', // Hover background color
    },
    '&.Mui-selected': {
      color: '#ffffff', // Selected icon color
      fontWeight: 'bold', 
    },
  },
}));
