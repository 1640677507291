import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import { ViewQuilt, DomainAdd, Check, Add, ListAlt } from '@mui/icons-material';
import CustomSlider from './CustomSlider'; 
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton'; 
import CheckIcon from '@mui/icons-material/Check';
import theme from './theme'; 
import axios from 'axios';
import ImageCanvas from './components/ImageCanvas';
import { ImageContainer, PositionedActionButton } from './components/Buttons';

const JobOptions = ({
  style,
  setStyle,
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
  numFloors,
  setNumFloors,
  color,
  setColor,
  handleIconClick,
}) => {
  const [jobs, setJobs] = useState([]);
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({
    clientFullName: '',
    clientEmail: '',
    clientPhone: '',
    clientStreetAddress: '',
    clientCity: '',
    clientState: '',
    clientPostalCode: '',
    clientCountry: '',
    clientPhoto: '/images/avatars/painter.webp',
    preferredContactMethod: '',
    notes: '',
    isActive: true,
    tags: [],
  });
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('jobs');
  const [jobName, setJobName] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [image, setImage] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(null);
  const [floorImages, setFloorImages] = useState(Array(parseInt(numFloors)).fill(null));
  const [floorRooms, setFloorRooms] = useState(Array(parseInt(numFloors)).fill([]));
  const [loading, setLoading] = useState(Array(parseInt(numFloors)).fill(false));
  const [errors, setErrors] = useState(Array(parseInt(numFloors)).fill(null));
  const [outputImageUrls, setOutputImageUrls] = useState(Array(parseInt(numFloors)).fill(null));

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const PIXELS_PER_METER = 50;
  const DEFAULT_AVATAR = '/images/avatars/painter.webp';

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const userId = getCookie('userId');

  useEffect(() => {
    if (userId) {
      fetchJobs();
      fetchClients();
    }
  }, [userId]);

  useEffect(() => {
    const floorsCount = parseInt(numFloors);
    setFloorImages(prev => prev.slice(0, floorsCount).concat(Array(Math.max(0, floorsCount - prev.length)).fill(null)));
    setFloorRooms(prev => prev.slice(0, floorsCount).concat(Array(Math.max(0, floorsCount - prev.length)).fill([])));
    setLoading(prev => prev.slice(0, floorsCount).concat(Array(Math.max(0, floorsCount - prev.length)).fill(false)));
    setErrors(prev => prev.slice(0, floorsCount).concat(Array(Math.max(0, floorsCount - prev.length)).fill(null)));
    setOutputImageUrls(prev => prev.slice(0, floorsCount).concat(Array(Math.max(0, floorsCount - prev.length)).fill(null)));
  }, [numFloors]);

  useEffect(() => {
    if (!numFloors) {
      setNumFloors("1");
    }
  }, []);

  useEffect(() => {
    const storedJobData = JSON.parse(localStorage.getItem('jobData'));
    if (storedJobData) {
      setJobName(storedJobData.JobDetails.JobName || '');
      if (storedJobData.ClientID) {
        const clientId = storedJobData.ClientID._id; 
        setSelectedClient(clientId);
      }
      if (storedJobData.FloorPlanID && storedJobData.FloorPlanID.Floors) {
        const calculatedNumFloors = storedJobData.FloorPlanID.Floors.length;
        setNumFloors(calculatedNumFloors.toString());
      } else {
        setNumFloors("1");
      }
    }
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/jobs`, {
        params: { userId }
      });
      console.log('Fetched jobs:', response.data);
      setJobs(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setJobs([]);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/clients`, {
        params: { userId }
      });
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleFloorChange = (event, newValue) => {
    if (newValue !== null) {
      setNumFloors(newValue);
    }
  };

  const handleJobClick = (job) => {
    setJobName(job.JobDetails.JobName);
    setSelectedClient(job.ClientID._id);
    setNumFloors(job.NumFloors ? job.NumFloors.toString() : "1"); 
    setStyle(job.JobDetails.Style || '');
    setCoverage(job.JobDetails.Coverage || 0);
    setMetersSquared(job.JobDetails.MetersSquared || 0);
    setRoomHeight(job.JobDetails.RoomHeight || 0);
    setWallCoats(job.JobDetails.WallCoats || '1');
    setRoomPrep(job.JobDetails.RoomPrep || 'low');
    setColor(job.JobDetails.Color || '');
    setSelectedOption('floors');
  };

  const handleSubmit = async () => {
    setIsUpdating(true);
    setUpdateSuccess(false);
  
    if (!jobName) {
      alert('Job Name is required');
      setIsUpdating(false);
      return;
    }
  
    if (!selectedClient) {
      alert('Client is required');
      setIsUpdating(false);
      return;
    }
  
    if (!userId) {
      alert('User ID is missing. Please log in again.');
      setIsUpdating(false);
      return;
    }
  
    const jobData = {
      JobDetails: {
        JobName: jobName,
        Style: style,
        Coverage: coverage,
        MetersSquared: metersSquared,
        RoomHeight: roomHeight,
        WallCoats: wallCoats,
        RoomPrep: roomPrep,
        Color: color,
      },
      ClientID: selectedClient,
      userId: userId,
      NumFloors: numFloors,
    };
  
    try {
      const jobResponse = await axios.post(`${API_ENDPOINT}/api/jobs`, jobData);
      console.log('Job created successfully:', jobResponse.data);

      localStorage.setItem('jobData', JSON.stringify(jobResponse.data));
  
      setIsUpdating(false);
      setUpdateSuccess(true);
  
      setTimeout(() => {
        setUpdateSuccess(false);
      }, 3000);

      // Refresh the jobs list
      fetchJobs();
    } catch (error) {
      console.error('Error creating job or floorplan:', error);
      setIsUpdating(false);
    }
  };

  const handleUpload = useCallback((event, floorIndex) => {
    const file = event.target.files[0];
    if (file) {
      setFloorImages(prev => {
        const newImages = [...prev];
        newImages[floorIndex] = file;
        return newImages;
      });
      setFloorRooms(prev => {
        const newRooms = [...prev];
        newRooms[floorIndex] = [];
        return newRooms;
      });
      setErrors(prev => {
        const newErrors = [...prev];
        newErrors[floorIndex] = null;
        return newErrors;
      });
    }
  }, []);

  const handleGenerateRooms = useCallback(async (floorIndex) => {
    if (!floorImages[floorIndex]) return;
  
    setLoading(prev => {
      const newLoading = [...prev];
      newLoading[floorIndex] = true;
      return newLoading;
    });
  
    const formData = new FormData();
    formData.append('floorplan', floorImages[floorIndex]);
  
    try {
      const response = await fetch(`${API_ENDPOINT}/api/upload-floorplan`, {
        method: 'POST',
        body: formData,
      });
  
      const data = await response.json();
      console.log('Response Data:', data);
  
      if (!data.rooms || !Array.isArray(data.rooms.floorplan)) {
        throw new Error('Invalid response format');
      }
  
      const floorplanRooms = data.rooms.floorplan;
      const storedJobData = JSON.parse(localStorage.getItem('jobData'));
  
      const selectedFloor = storedJobData.FloorPlanID.Floors[floorIndex];
      const selectedFloorID = selectedFloor._id;
  
      const fullRoomData = [];
  
      for (let i = 0; i < floorplanRooms.length; i++) {
        const roomData = floorplanRooms[i];
  
        const room = {
          id: `room-${i}`,
          name: roomData.name,
          width: roomData.x * PIXELS_PER_METER,
          length: roomData.y * PIXELS_PER_METER,
          x: roomData['x-centre'] - (roomData.x * PIXELS_PER_METER) / 2,
          y: roomData['y-centre'] - (roomData.y * PIXELS_PER_METER) / 2,
          floor: floorIndex + 1,
          doors: 0,
          windows: 0,
          walls: {
            top: { color: 'rgb(255,255,255)' },
            bottom: { color: 'rgb(255,255,255)' },
            left: { color: 'rgb(255,255,255)' },
            right: { color: 'rgb(255,255,255)' },
          },
          ceiling: { color: 'rgb(255,255,255)' },
          architraves: { color: 'rgb(255,255,255)' },
          skirtingBoards: { color: 'rgb(255,255,255)' },
        };
  
        try {
          const addedRoom = await axios.post(`${API_ENDPOINT}/api/rooms`, room);
          console.log('Room successfully added to database:', addedRoom.data);
          fullRoomData.push(addedRoom.data);
        } catch (error) {
          console.error('Error adding room to database:', error.message);
        }
      }
  
      const updatedFloors = storedJobData.FloorPlanID.Floors.map((floor, index) => {
        if (index === floorIndex) {
          return {
            ...floor,
            rooms: fullRoomData,
          };
        }
        return floor;
      });
  
      storedJobData.FloorPlanID.Floors = updatedFloors;
      localStorage.setItem('jobData', JSON.stringify(storedJobData));
  
      setFloorRooms(prev => {
        const newRooms = [...prev];
        newRooms[floorIndex] = fullRoomData;
        return newRooms;
      });
  
    } catch (error) {
      console.error('Error processing floorplan:', error);
      setErrors(prev => {
        const newErrors = [...prev];
        newErrors[floorIndex] = 'Error processing floorplan';
        return newErrors;
      });
    } finally {
      setLoading(prev => {
        const newLoading = [...prev];
        newLoading[floorIndex] = false;
        return newLoading;
      });
    }
  }, [floorImages]);

  const renderJobList = () => (
    <Box p={0} sx={{ ...theme.innerBox, width: '100%' }}>
      <Box sx={theme.customFormBox}>
      <Typography  sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif'  }} gutterBottom>Jobs List</Typography>
      {Array.isArray(jobs) && jobs.length > 0 ? (
        <List>
          {jobs.map((job) => (
            <ListItem key={job._id} button onClick={() => handleJobClick(job)}>
              <ListItemText 
                primary={job.JobDetails.JobName} 
                secondary={`Client: ${job.ClientID.clientFullName}`} 
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No jobs found. Create a new job to get started.</Typography>
      )}
      </Box>
    </Box>
  );

  const renderJobOptions = () => {
    switch (selectedOption) {
      case 'jobs':
        return renderJobList();
      case 'floors':
        return (
          <Box sx={theme.innerBox}>
            <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="subtitle1" gutterBottom sx={theme.customToggleButton}>Job Details</Typography>
              <TextField
                label="Job Name"
                name="jobName"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                fullWidth
                sx={theme.inputStyle}
              />
          
              <FormControl fullWidth sx={theme.inputStyle}>
                <InputLabel id="client-select-label">Client</InputLabel>
                <Select
                  labelId="client-select-label"
                  id="client-select"
                  value={selectedClient}
                  label="Client"
                  onChange={(e) => setSelectedClient(e.target.value)}
                >
                  {Array.isArray(clients) && clients.map((client) => (
                    <MenuItem key={client._id} value={client._id}>
                      {client.clientFullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          
              <Typography variant="subtitle1" gutterBottom sx={theme.customToggleButton}>Number of floors</Typography>
              <CustomToggleButtonGroup 
                value={numFloors}
                exclusive
                onChange={handleFloorChange}
                fullWidth 
              >
                <CustomToggleButton value="1" sx={theme.customToggleButton}>1</CustomToggleButton>
                <CustomToggleButton value="2" sx={theme.customToggleButton}>2</CustomToggleButton>
                <CustomToggleButton value="3" sx={theme.customToggleButton}>3</CustomToggleButton>
                <CustomToggleButton value="4" sx={theme.customToggleButton}>4</CustomToggleButton>
                <CustomToggleButton value="5" sx={theme.customToggleButton}>5</CustomToggleButton>
              </CustomToggleButtonGroup>
          
              <Button
                variant="contained"
                color={updateSuccess ? "success" : "primary"}
                onClick={handleSubmit}
                fullWidth
                sx={{ 
                  mt: 2, 
                  ...(updateSuccess && theme.successButton),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  "Updating..."
                ) : updateSuccess ? (
                  <>
                    Job Updated
                    <Check style={{ marginLeft: '8px' }} />
                  </>
                ) : (
                  <>
                    {selectedClientId ? 'Update Job' : 'Create New Job'}
                    <Add style={{ marginLeft: '8px' }} />
                  </>
                )}
              </Button>
            </Box>
          </Box>
        );
      case 'upload':
        return (
          <Box sx={theme.innerBox}>
            {Array.from({ length: parseInt(numFloors) }, (_, index) => (
              <Box key={index} sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>
                    Floor {index + 1}
                  </Typography>
                  <ImageContainer>
                    <ImageCanvas 
                      image={floorImages[index]} 
                      style={{ 
                        width: '100%', 
                        height: '150px',
                        borderRadius: '8px',
                        outlineStyle: 'solid', 
                        outlineColor: 'rgba(0, 0, 0, 0.3)'
                      }} 
                    />
                    <PositionedActionButton
                      variant="contained"
                      component={floorImages[index] ? 'button' : 'label'}
                      onClick={floorImages[index] ? () => handleGenerateRooms(index) : null}
                      disabled={loading[index]}
                    >
                      {loading[index] ? (
                        <CircularProgress size={24} />
                      ) : floorImages[index] ? (
                        floorRooms[index]?.length > 0 ? <CheckIcon /> : 'Generate Rooms'
                      ) : (
                        'Upload Floorplan'
                      )}
                      {!floorImages[index] && (
                        <input 
                          type="file" 
                          accept="image/*" 
                          onChange={(e) => handleUpload(e, index)} 
                          required 
                          hidden 
                        />
                      )}
                    </PositionedActionButton>
                  </ImageContainer>
                  {floorRooms[index]?.length > 0 && (
                    <Box mt={2}>
                      <Typography variant="subtitle1">Rooms:</Typography>
                      <ul>
                        {floorRooms[index].map(room => (
                          <li key={room.id}>{room.name}: {room.width}px x {room.length}px</li>
                        ))}
                      </ul>
                    </Box>
                  )}
                  {errors[index] && <Typography color="error" mt={2}>{errors[index]}</Typography>}
                </Box>
              </Box>
            ))}
          </Box>
        );
      case 'paint':
        return (
          <Box sx={theme.innerBox}>
            <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: 'bold', margin: '5px 5px', color: 'black' }}>
                Job Options
              </Typography>
              
              <Typography sx={theme.customToggleButton}>Paint Coverage</Typography>
              <CustomSlider value={coverage} onChange={(e, val) => setCoverage(val)} valueLabelDisplay="auto" min={10} max={20}/>
              <Typography sx={theme.customToggleButton}>Productivity (m²/hour)</Typography>
              <CustomSlider value={metersSquared} onChange={(e, val) => setMetersSquared(val)} valueLabelDisplay="auto" min={10} max={20} />
              <Typography sx={theme.customToggleButton}>Room Height</Typography>
              <CustomSlider value={roomHeight} onChange={(e, val) => setRoomHeight(val)} valueLabelDisplay="auto" min={2.5} max={7.0} step={0.1}/>
              <Box my={2}>
                <Typography sx={theme.customToggleButton}>Wall Coats</Typography>
                <CustomToggleButtonGroup value={wallCoats} exclusive onChange={(e, val) => setWallCoats(val)} fullWidth>
                  <CustomToggleButton value="1" sx={theme.customToggleButton}>One</CustomToggleButton>
                  <CustomToggleButton value="2" sx={theme.customToggleButton}>Two</CustomToggleButton>
                  <CustomToggleButton value="3" sx={theme.customToggleButton}>Three</CustomToggleButton>
                </CustomToggleButtonGroup>
              </Box>
              <Box my={2}>
                <Typography sx={theme.customToggleButton}>Room Prep</Typography>
                <CustomToggleButtonGroup value={roomPrep} exclusive onChange={(e, val) => setRoomPrep(val)} fullWidth>
                  <CustomToggleButton value="low" sx={theme.customToggleButton}>Low</CustomToggleButton>
                  <CustomToggleButton value="medium" sx={theme.customToggleButton}>Med</CustomToggleButton>
                  <CustomToggleButton value="high" sx={theme.customToggleButton}>High</CustomToggleButton>
                </CustomToggleButtonGroup>
              </Box>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomToggleButton value="jobs"><ListAlt /></CustomToggleButton>
          <CustomToggleButton value="floors"><ViewQuilt /></CustomToggleButton>
          <CustomToggleButton value="upload"><DomainAdd /></CustomToggleButton>
          <CustomToggleButton value="paint"><ViewQuilt /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      {renderJobOptions()}
      {selectedOption === 'jobs' && jobs.length === 0 && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedOption('floors')}
            fullWidth
          >
            Create New Job
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default JobOptions;