import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { ImageContainer, Canvas, PositionedActionButton } from './components/Buttons';
import { drawImageOnCanvas, rgbToHex, getContrastColor, b64toBlob } from './utils/utils.js';
import { roomTypes } from './constants/constants.js'; // 



// Custom styled components for the form controls
const StyledFormControl = styled(FormControl)({
  minWidth: '120px',
  marginRight: '16px',
  height: '40px', // Added height
  '& .MuiInputLabel-root': {
    fontSize: '0.9rem',
    color: '#333', // Label color
  },
  '& .MuiSelect-root': {
    fontSize: '0.9rem',
    color: '#000', // Select text color
  },
  '& .MuiOutlinedInput-root': {
    height: '100%', // Ensure full height usage
    '& fieldset': {
      borderColor: '#007bff', // Border color
    },
    '&:hover fieldset': {
      borderColor: '#0056b3', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#28a745', // Focus border color
    },
  },
});

const StyledTextField = styled(TextField)({
  minWidth: '200px',
  height: '40px', // Added height
  '& .MuiInputBase-root': {
    fontSize: '0.9rem',
    color: '#000', // Input text color
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.9rem',
    color: '#333', // Label color
  },
  '& .MuiOutlinedInput-root': {
    height: '100%', // Ensure full height usage
    '& fieldset': {
      borderColor: '#007bff', // Border color
    },
    '&:hover fieldset': {
      borderColor: '#0056b3', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#28a745', // Focus border color
    },
  },
});

const SwatchBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px',
  borderRadius: '10px',
  backgroundColor: '#fff',
  margin: '15px 5px',
  dropshadow: '0px 0px 20px rgba(0, 0, 0, 1)',
});

const SwatchColorBox = styled(Box)({
  width: '150px',
  height: '175px',
  borderRadius: '8px',
  border: '0px solid #333',
  marginBottom: '5px',
  position: 'relative',
});

const SwatchHex = styled(Typography)(({ contrastcolor }) => ({
  position: 'absolute',
  bottom: '5px',
  left: '10px',
  color: contrastcolor,
  fontSize: '14px',
}));

const SwatchName = styled(Typography)({
  marginTop: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#222',
});

const CONTRAST_THRESHOLD = 3;

const Palette = ({ colorChartPath, onSwatchClick }) => {
  const [image, setImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [updatedImageUrl, setUpdatedImageUrl] = useState('');
  const [swatchData, setSwatchData] = useState(null);

  const [roomType, setRoomType] = useState('');
  const [paletteName, setPaletteName] = useState('');

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const inputCanvasRef = useRef(null);
  const outputCanvasRef = useRef(null);

  useEffect(() => {
    const savedImage = localStorage.getItem('image');
    const savedUpdatedImageUrl = localStorage.getItem('updatedImageUrl');
    const savedSwatchData = localStorage.getItem('swatchData');

    if (savedImage) {
      const blob = b64toBlob(savedImage);
      setImage(blob);
    }
    if (savedUpdatedImageUrl) setUpdatedImageUrl(savedUpdatedImageUrl);
    if (savedSwatchData) setSwatchData(JSON.parse(savedSwatchData));
  }, []);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        sessionStorage.setItem('image', reader.result.split(',')[1]); // Use sessionStorage instead
      };
      reader.readAsDataURL(image);
    }
  }, [image]);

  useEffect(() => {
    if (updatedImageUrl) {
      localStorage.setItem('updatedImageUrl', updatedImageUrl);
    }
  }, [updatedImageUrl]);

  useEffect(() => {
    if (swatchData) {
      localStorage.setItem('swatchData', JSON.stringify(swatchData));
    }
  }, [swatchData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Clear local storage and reset state
      localStorage.removeItem('image');
      localStorage.removeItem('updatedImageUrl');
      localStorage.removeItem('swatchData');

      setImage(null);
      setUpdatedImageUrl('');
      setSwatchData(null);
      setError(null);
      setImage(file);

      // Clear canvas contexts
      if (inputCanvasRef.current) {
        const inputCtx = inputCanvasRef.current.getContext('2d');
        inputCtx.clearRect(0, 0, inputCanvasRef.current.width, inputCanvasRef.current.height);
      }
      if (outputCanvasRef.current) {
        const outputCtx = outputCanvasRef.current.getContext('2d');
        outputCtx.clearRect(0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height);
      }
    }
  };

  const handleUploadOrGenerate = async () => {
    if (!image) return;

    if (updatedImageUrl) {
      // If the image is already uploaded and processed, perform the generation step
      handleGenerate();
    } else {
      // Otherwise, perform the upload step
      setUploading(true);

      const formData = new FormData();
      formData.append('file', image);

      try {
        const response = await axios.post(`${API_ENDPOINT}/api/generate-colour-swatch`, formData);
        if (response.status === 200) {
          const data = response.data;

          // Load existing palettes from local storage
          const existingPalettes = JSON.parse(localStorage.getItem('savedPalettes') || '[]');

          // Use the name entered by the user
          const newPaletteName = paletteName.trim() || `palette_${String(existingPalettes.length + 1).padStart(3, '0')}`;

          // Create new palette
          const newPalette = {
            name: newPaletteName,
            swatchData: data.swatchData
          };

          // Combine existing and new palettes
          const updatedPalettes = [...existingPalettes, newPalette];

          // Save the combined palettes to local storage
          localStorage.setItem('savedPalettes', JSON.stringify(updatedPalettes));

          setUpdatedImageUrl(data.updatedImageUrl);
          setSwatchData(data.swatchData);
        } else {
          console.error('Failed to upload the photo');
        }
      } catch (error) {
        console.error('Error uploading photo:', error);
        setError('Error uploading photo');
      } finally {
        setUploading(false);
      }
    }
  };

  const handleGenerate = async () => {
    // Handle the generate functionality after the image has been uploaded
    console.log('Generate functionality goes here');
  };

  useEffect(() => {
    if (image) {
      drawImageOnCanvas(inputCanvasRef, URL.createObjectURL(image), 500);
    }
  }, [image]);
  
  useEffect(() => {
    if (updatedImageUrl) {
      drawImageOnCanvas(outputCanvasRef, updatedImageUrl, 500);
    }
  }, [updatedImageUrl]);


  return (
    <Box p={3} border="0px solid #ccc" borderRadius="8px" style={{
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
      width: '100%'
    }}>
      <Typography variant="h6" gutterBottom>
        Create your custom palette
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center" mt={2}>
          <StyledFormControl variant="outlined">
            <InputLabel id="room-type-label">Room Type</InputLabel>
            <Select
              labelId="room-type-label"
              value={roomType}
              onChange={(e) => setRoomType(e.target.value)}
              label="Room Type"
            >
              {roomTypes.map((room) => (
                <MenuItem key={room} value={room}>
                  {room}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledTextField
            label="Palette Name"
            value={paletteName}
            onChange={(e) => setPaletteName(e.target.value)}
            variant="outlined"
          />
        </Box>
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Box mt={2}>
        <Box display="flex" justifyContent="space-around" mt={2} style={{ backgroundColor: 'rgba(255, 255, 255, 0)', padding: '10px 10px', borderRadius: '10px'}}>
          <ImageContainer>
            <Canvas ref={inputCanvasRef} style={{ backgroundColor: '#ccc', width: '400px', height: '300px', position: 'relative',borderRadius: '10px' }} />
            <PositionedActionButton variant="contained" component="label" onClick={handleUploadOrGenerate} disabled={uploading}>
              {uploading ? <CircularProgress size={24} /> : (image && !updatedImageUrl ? 'Generate' : 'Upload Image')}
              <input type="file" accept="image/*" onChange={handleFileChange} hidden />
            </PositionedActionButton>
          </ImageContainer>
          <ImageContainer>
            <Canvas ref={outputCanvasRef} style={{ backgroundColor: '#ccc', width: '400px', height: '300px', position: 'relative',borderRadius: '10px' }} />
          </ImageContainer>
        </Box>
      </Box>
      {swatchData && (
  <Box mt={4} style={{
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(5px)',
    WebkitBackdropFilter: 'blur(5px)',
    width: '100%'
  }}>
    <Box
      display="grid"
      gridTemplateColumns="repeat(5, 1fr)" // 5 columns
      gridTemplateRows="repeat(2, auto)" // 2 rows
      gap={2} // space between items

    >
      {swatchData.slice(0, 10).map((color, index) => {
        const rgbValues = color.rgb.match(/\d+/g);
        const rgbString = rgbValues ? `rgb(${rgbValues.join(', ')})` : 'rgb(255, 255, 255)';
        const hexValue = rgbToHex(color.rgb);
        const contrastcolor = getContrastColor(hexValue);
        return (
          <SwatchBox
            key={index}
            onClick={() => onSwatchClick(color.rgb)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <SwatchColorBox bgcolor={rgbString}>
              <SwatchHex contrastcolor={contrastcolor}>{hexValue}</SwatchHex>
            </SwatchColorBox>
            <SwatchName>{color.name}</SwatchName>
          </SwatchBox>
        );
      })}
    </Box>
  </Box>
)}


    </Box>
  );
};

export default Palette;
