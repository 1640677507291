import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, Tooltip, tooltipClasses } from '@mui/material';
import { Info, Business, ConfirmationNumber, Gavel, Home, LocationCity, LocationOn, Public, Place, Check, Gradient, MonetizationOn, AccountBalance, Share } from '@mui/icons-material';
import axios from 'axios';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import theme from './theme';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '16px',
    padding: '8px',
  },
}));

const AccountPanel = ({
  companyName, setCompanyName,
  companyNumber, setCompanyNumber,
  vatNumber, setVatNumber,
  streetAddress, setStreetAddress,
  city, setCity,
  state, setState,
  postalCode, setPostalCode,
  country, setCountry,
  logo, setLogo,
  email, setEmail,
  phoneNumber, setPhoneNumber,
  whatsApp, setWhatsApp,
  website, setWebsite,
  socialMedia, setSocialMedia,
  primaryContactName, setPrimaryContactName,
  primaryContactPosition, setPrimaryContactPosition,
  secondaryContactName, setSecondaryContactName,
  secondaryContactPhone, setSecondaryContactPhone,
  industryType, setIndustryType,
  businessType, setBusinessType,
  preferredContactMethod, setPreferredContactMethod,
  notes, setNotes,
  paymentTerms, setPaymentTerms,
  creditLimit, setCreditLimit,
  isActive, setIsActive,
  sortCode, setSortCode,
  accountNumber, setAccountNumber,
  tags, setTags, 
}) => {
  const [selectedOption, setSelectedOption] = useState('info');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [newLogoFile, setNewLogoFile] = useState(null);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const DEFAULT_LOGO = '/images/avatars/painter.webp';

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const userId = getCookie('userId');

  useEffect(() => {
    const storedAccountData = localStorage.getItem('accountData');
    if (storedAccountData) {
      const accountData = JSON.parse(storedAccountData);
      const account = Array.isArray(accountData) ? accountData[0] : accountData;

      setCompanyName(account.companyName || 'Your Company');
      setCompanyNumber(account.companyNumber || '12345678');
      setVatNumber(account.vatNumber || '');
      setStreetAddress(account.streetAddress || '');
      setCity(account.city || '');
      setState(account.state || '');
      setPostalCode(account.postalCode || '');
      setCountry(account.country || '');
      setLogo(account.logo || DEFAULT_LOGO);
      setEmail(account.email || '');
      setPhoneNumber(account.phoneNumber || '');
      setWhatsApp(account.whatsApp || '');
      setWebsite(account.website || '');
      setSocialMedia(account.socialMedia || {});
      setPrimaryContactName(account.primaryContactName || '');
      setPrimaryContactPosition(account.primaryContactPosition || '');
      setSecondaryContactName(account.secondaryContactName || '');
      setSecondaryContactPhone(account.secondaryContactPhone || '');
      setIndustryType(account.industryType || '');
      setBusinessType(account.businessType || '');
      setPreferredContactMethod(account.preferredContactMethod || '');
      setNotes(account.notes || '');
      setPaymentTerms(account.paymentTerms || '');
      setCreditLimit(account.creditLimit || 0);
      setIsActive(account.isActive !== undefined ? account.isActive : true);
      setTags(account.tags || []);
      setSortCode(account.sortCode || '00-00-00');
      setAccountNumber(account.accountNumber || '12345678')

    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewLogoFile(file);
      setLogo(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const userId = getCookie('userId');
    console.log('User ID:', userId); // Debugging line
  
    if (!companyName) {
      alert('Company Name is required');
      return;
    }
  
    if (!userId) {
      alert('User ID is missing. Please log in again.');
      return;
    }
  
    const accountData = {
      companyName,
      companyNumber,
      vatNumber,
      streetAddress,
      city,
      state,
      postalCode,
      country,
      email,
      phoneNumber,
      whatsApp,
      website,
      socialMedia,
      primaryContactName,
      primaryContactPosition,
      secondaryContactName,
      secondaryContactPhone,
      industryType,
      businessType,
      preferredContactMethod,
      notes,
      paymentTerms,
      creditLimit,
      isActive,
      sortCode,
      accountNumber,
      tags,
    };
  
    try {
      setIsUpdating(true);
  
      if (newLogoFile) {
        const formData = new FormData();
        formData.append('logo', newLogoFile);
        formData.append('userId', userId);
  
        console.log('Uploading logo...');
        const logoResponse = await axios.post(`${API_ENDPOINT}/api/uploadlogo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        console.log('Logo upload response:', logoResponse.data);
        accountData.logo = logoResponse.data.logoUrl;
      }
  
      const response = await axios.post(`${API_ENDPOINT}/api/accounts`, { userId, ...accountData });
      localStorage.setItem('accountData', JSON.stringify(response.data));
  
      setIsUpdating(false);
      setUpdateSuccess(true);
      setNewLogoFile(null);
      setTimeout(() => {
        setUpdateSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error updating account:', error);
      setIsUpdating(false);
    }
  };
  

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const renderOptionInputs = () => {
    switch (selectedOption) {
      case 'info':
        return (
          <Box sx={theme.innerBox}>
            <Box sx={theme.customFormBox}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Company Logo</Typography>
              <Box
                sx={{
                  width: '100%',
                  height: 150,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '2px solid grey',
                  borderRadius: '8px',
                  marginBottom: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  },
                }}
                onClick={() => document.getElementById('company-logo-input').click()}
              >
                <img
                  src={logo || DEFAULT_LOGO}
                  alt="Company Logo"
                  style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '6px', cursor: 'pointer'}}
                />
              </Box>
            </Box>
            <input
              id="company-logo-input"
              type="file"
              hidden
              onChange={handleImageUpload}
            />
           <Box sx={{ ...theme.customFormBox, zIndex: 200 }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                Company Details
              </Typography>
              <TextField
                label="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Company Number"
                value={companyNumber}
                onChange={(e) => setCompanyNumber(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumber />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="VAT Number"
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Gavel />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box sx={{ ...theme.customFormBox }}>
              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                Address Details
              </Typography>
              <TextField
                label="Street Address"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Home />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationCity />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="State/Province"
                value={state}
                onChange={(e) => setState(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Postal/ZIP Code"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Place />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Public />
                    </InputAdornment>
                  ),
                }}
              />
            
              <Button
                variant="contained"
                color={updateSuccess ? "success" : "primary"}
                onClick={handleSubmit}
                fullWidth
                sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
                disabled={isUpdating}
              >
                {isUpdating ? "Updating..." : updateSuccess ? "Account Updated" : "Update Details"}
                {updateSuccess && <Check style={{ marginLeft: '4px' }} />}
              </Button>
            </Box>
          </Box>
        );
      case 'invoicing':
        return (
          <Box>
            <Typography>Payment/Invoicing Options</Typography>
          </Box>
        );
      case 'banking':
        return (
          <Box sx={theme.innerBox}>
          <Box sx={theme.customFormBox}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif' }} gutterBottom>Banking Details</Typography>
              <TextField
                label="Sort Code"
                value={sortCode}
                onChange={(e) => setSortCode(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Business />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Account Number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ConfirmationNumber />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="VAT Number"
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
                fullWidth
                variant="outlined"
                sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Gavel />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            </Box>
        );
      case 'social':
        return (
          <Box>
            <Typography>Social Media Accounts</Typography>
          </Box>
        );
      
      default:
        return null;
    }
  };

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomTooltip title="Account details">
            <CustomToggleButton value="info"><Info /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Banking and Payments">
            <CustomToggleButton value="banking"><AccountBalance /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Invoicing">
            <CustomToggleButton value="invoicing"><MonetizationOn /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title="Social Media">
            <CustomToggleButton value="social"><Share /></CustomToggleButton>
          </CustomTooltip>
          {/* <CustomTooltip title="Gradient">
            <CustomToggleButton value="gradient"><Gradient /></CustomToggleButton>
          </CustomTooltip> */}
        </CustomToggleButtonGroup>
      </Box>
      {renderOptionInputs()}
    </Box>
  );
};

export default AccountPanel;
