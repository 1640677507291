import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import SubscriptionForm from './components/SubscriptionForm';
// import CheckoutForm from './components/CheckoutForm';
import Login from './Login';
import Register from './Register'; 
import Dashboard from './Dashboard'; 
import withAuth from './hooks/withAuth.js';

// const stripePromise = loadStripe('pk_test_51Ohc3pCBop2DWPGzBXbeNFSxdgmFRmFt6uCcP03kwhsiYzXVV4vFQJjVXBYwARG1PrJOjW4FpXGV0QABiwsl07XN00xda6MXhZ');

const ProtectedDashboard = withAuth(Dashboard);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedDashboard />} />
        {/* <Route path="/checkout" element={
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        } />
        <Route path="/subscribe" element={
          <Elements stripe={stripePromise}>
            <SubscriptionForm email={'testuser@gmail.com'} />
          </Elements>
        } /> */}
        <Route path="/" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
};

export default App;
