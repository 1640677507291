// VariablePanel.js
import React from 'react';
import { Box, Collapse, Button, Typography } from '@mui/material';

const VariablePanel = ({ variables, isOpen, togglePanel }) => {
  return (
    <Box sx={{ position: 'absolute', top: 20, right: 20, zIndex: 1000 }}>
      <Button variant="contained" onClick={togglePanel}>
        {isOpen ? 'Hide Variables' : 'Show Variables'}
      </Button>
      <Collapse in={isOpen}>
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '16px',
            marginTop: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <Typography variant="h6">App Variables</Typography>
          <Typography>User ID: {variables.userId}</Typography>
          <Typography>Current Account ID: {variables.currentAccountID}</Typography>
          <Typography>Current Contractor ID: {variables.currentContractorID}</Typography>
          <Typography>Current Client ID: {variables.currentClientID}</Typography>
          <Typography>Current Job ID: {variables.currentJobID}</Typography>
          <Typography>Current Floorplan ID: {variables.currentFloorplanID}</Typography>
          <Typography>Current Floor ID: {variables.currentFloorID}</Typography>
          <Typography>Current Room ID: {variables.currentRoomID}</Typography>
          <Typography>Current Palette ID: {variables.currentPaletteID}</Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default VariablePanel;