import React from 'react';
import { Button, Box } from '@mui/material';

export const UploadButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#007bff',
      color: 'white',
      '&:hover': {
        backgroundColor: '#28a745',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const ActionButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#007bff',
      fontSize: '12px',
      fontWeight: 'bold',
      color: 'white',
      '&:hover': {
        backgroundColor: '#28a745',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const DownloadButton = ({ children, ...props }) => (
  <Button
    sx={{
      margin: '10px 0',
      backgroundColor: '#28a745',
      color: 'white',
      '&:hover': {
        backgroundColor: '#218838',
      },
    }}
    {...props}
  >
    {children}
  </Button>
);

export const ImageContainer = ({ children, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      textAlign: 'center',
    }}
    {...props}
  >
    {children}
  </Box>
);

export const Canvas = React.forwardRef((props, ref) => (
  <canvas
    ref={ref}
    style={{
      width: '95%',
      borderRadius: '8px',
    }}
    {...props}
  />
));

export const PositionedActionButton = ({ children, ...props }) => (
  <ActionButton
    sx={{
      position: 'absolute',
      bottom: '15px',
      right: '20px',
    }}
    {...props}
  >
    {children}
  </ActionButton>
);