// Check if the user is authenticated by verifying the presence of a token
export const checkAuthStatus = () => {
  const token = localStorage.getItem('token');
  return !!token;
};

// Get the token from local storage
export const getToken = () => {
  return localStorage.getItem('token');
};

// Set the token to local storage
export const setToken = (token) => {
  localStorage.setItem('token', token);
};

// Remove the token from local storage, effectively logging the user out
export const removeToken = () => {
  localStorage.removeItem('token');
};
