import React from 'react';
import { Box, Typography, Tooltip, tooltipClasses } from '@mui/material';
import { Stage, Layer, Rect, Text, Path } from 'react-konva';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import { ViewStream, TableRows, TableChart, ViewDay } from '@mui/icons-material';
import './InvoicePanel.css';

const InvoicePanel = ({ totalCost, invoicesState, setInvoicesState, setNumInvoices }) => {
  const containerRef = React.useRef(null);
  const [numInvoices, setNumInvoicesState] = React.useState(invoicesState.length);
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [containerHeight, setContainerHeight] = React.useState(0);
  const [selectedInvoice, setSelectedInvoice] = React.useState(null);
  const PADDING = 20; // Define the padding value

  React.useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
      setContainerHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef.current]);

  React.useEffect(() => {
    if (numInvoices !== invoicesState.length) {
      const newInvoices = Array.from({ length: numInvoices }, (_, i) => ({
        id: i + 1,
        percentage: Math.floor(100 / numInvoices),
      }));

      // Adjust the last invoice to ensure the total is 100%
      const totalAssigned = newInvoices.reduce((acc, invoice) => acc + invoice.percentage, 0);
      newInvoices[newInvoices.length - 1].percentage += 100 - totalAssigned;

      setInvoicesState(newInvoices);
      setNumInvoices(numInvoices);
    }
  }, [numInvoices, setInvoicesState, invoicesState.length, setNumInvoices]);

  const handleLineDrag = (e, index) => {
    const newInvoices = [...invoicesState];
    const totalHeight = containerHeight - 2 * PADDING;
    const newY = e.target.y() - PADDING;
    const newPercentage = Math.round((newY / totalHeight) * 100);

    const prevInvoiceHeight = invoicesState.slice(0, index).reduce((acc, inv) => acc + inv.percentage, 0);
    const currentHeight = invoicesState[index].percentage;
    const nextHeight = invoicesState[index + 1].percentage;

    if (newPercentage <= prevInvoiceHeight || newPercentage >= prevInvoiceHeight + currentHeight + nextHeight) {
      return;
    }

    newInvoices[index].percentage = newPercentage - prevInvoiceHeight;
    newInvoices[index + 1].percentage = currentHeight + nextHeight - newInvoices[index].percentage;

    setInvoicesState(newInvoices);
  };

  const handleInvoiceClick = (index) => {
    setSelectedInvoice(index === selectedInvoice ? null : index);
  };

  const drawRoundedRect = (x, y, width, height, radius) => {
    return `
      M ${x + radius} ${y}
      L ${x + width - radius} ${y}
      Q ${x + width} ${y} ${x + width} ${y + radius}
      L ${x + width} ${y + height - radius}
      Q ${x + width} ${y + height} ${x + width - radius} ${y + height}
      L ${x + radius} ${y + height}
      Q ${x} ${y + height} ${x} ${y + height - radius}
      L ${x} ${y + radius}
      Q ${x} ${y} ${x + radius} ${y}
      Z
    `;
  };

  React.useEffect(() => {
    setNumInvoices(numInvoices);
  }, [numInvoices, setNumInvoices]);

  return (
    <Box p={2} border="0px solid #fff" borderRadius="8px" boxShadow={0} style={{
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)', // For Safari support
      height: '90%', // Ensure the box takes up the full height
      overflow: 'hidden', // Prevent overflow
      position: 'relative' // Ensure relative positioning for inner elements
    }}>
      <Box my={2}>
        <Typography gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 0px', color: 'black' }}>Invoices</Typography>
        <CustomToggleButtonGroup value={numInvoices} exclusive onChange={(e, val) => setNumInvoicesState(val)} fullWidth>
          <CustomToggleButton value={1}>1</CustomToggleButton>
          <CustomToggleButton value={2}>2</CustomToggleButton>
          <CustomToggleButton value={3}>3</CustomToggleButton>
          <CustomToggleButton value={4}>4</CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box my={2}>
        <CustomToggleButtonGroup exclusive fullWidth>
          <CustomToggleButton value={1}><ViewStream /></CustomToggleButton>
          <CustomToggleButton value={2}><TableRows /></CustomToggleButton>
          <CustomToggleButton value={3}><TableChart /></CustomToggleButton>
          <CustomToggleButton value={4}><ViewDay /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <div className="konva-container" ref={containerRef} style={{ flex: 1, height: 'calc(100% - 160px)' }}>
        <Stage width={containerWidth * 0.95} height={containerHeight + 2 * PADDING}>
          <Layer y={PADDING}>
            {invoicesState.map((invoice, index) => {
              const topY = invoicesState.slice(0, index).reduce((acc, inv) => acc + (inv.percentage / 100) * (containerHeight - 2 * PADDING), 0);
              const height = (invoice.percentage / 100) * (containerHeight - 2 * PADDING);
              const handleY = topY + height;
              const rectPath = drawRoundedRect(20, topY, containerWidth * 0.95 - 40, height, 10);

              return (
                <React.Fragment key={index}>
                  <Path
                    data={rectPath}
                    fill={selectedInvoice === index ? 'lightblue' : 'rgba(250,250,250, 0.8)'}
                    stroke={selectedInvoice === index ? 'blue' : 'grey'}
                    strokeWidth={1}
                    onClick={() => handleInvoiceClick(index)}
                  />
                  <Text
                    x={20 + (containerWidth * 0.95 - 40) / 2} // Center horizontally
                    y={topY + height / 2 - 50} // Center vertically for the first line of text
                    text={`Invoice ${invoice.id}`}
                    fontSize={18}
                    fill="black"
                    align="center"
                    width={containerWidth * 0.95 - 40} // Set width for alignment
                    offsetX={(containerWidth * 0.95 - 40) / 2} // Adjust offset to center
                    onClick={() => handleInvoiceClick(index)}
                  />
                  <Text
                    x={20 + (containerWidth * 0.95 - 40) / 2} // Center horizontally
                    y={topY + height / 2 - 20} // Center vertically for the second line of text
                    text={`${invoice.percentage}%`}
                    fontSize={18}
                    fill="black"
                    align="center"
                    width={containerWidth * 0.95 - 40} // Set width for alignment
                    offsetX={(containerWidth * 0.95 - 40) / 2} // Adjust offset to center
                    onClick={() => handleInvoiceClick(index)}
                  />
                  <Text
                    x={20 + (containerWidth * 0.95 - 40) / 2} // Center horizontally
                    y={topY + height / 2 + 10} // Center vertically for the third line of text
                    text={`$${((invoice.percentage / 100) * totalCost).toFixed(2)}`}
                    fontSize={18}
                    fill="black"
                    align="center"
                    width={containerWidth * 0.95 - 40} // Set width for alignment
                    offsetX={(containerWidth * 0.95 - 40) / 2} // Adjust offset to center
                    onClick={() => handleInvoiceClick(index)}
                  />
                  {index < invoicesState.length - 1 && (
                    <Rect
                      x={containerWidth * 0.95 / 2 - 30}
                      y={handleY - 10}
                      width={60}
                      height={20}
                      fill="blue"
                      draggable
                      dragBoundFunc={(pos) => ({
                        x: containerWidth * 0.95 / 2 - 30,
                        y: pos.y,
                      })}
                      onDragMove={(e) => handleLineDrag(e, index)}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Layer>
        </Stage>
      </div>
    </Box>
  );
};

export default InvoicePanel;
