import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Select, MenuItem, FormControl, InputLabel, Slider, Divider  } from '@mui/material';
import { Person, Mail, Phone, Work, Add, Check, Delete, AttachMoney, Timeline, AddAPhoto, RecentActors } from '@mui/icons-material';
import axios from 'axios';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import theme from './theme';

const ContractorPanel = () => {
  const [contractors, setContractors] = useState([]);
  const [newContractor, setNewContractor] = useState({
    fullName: '',
    email: '',
    phone: '',
    specialty: '',
    photo: '/images/avatars/painter.webp',
    hourlyRate: 20,
    experience: 0,
  });
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('list');
  const [newPhotoFile, setNewPhotoFile] = useState(null);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const DEFAULT_AVATAR = '/images/avatars/painter.webp';

  const contractorTypes = [
    'Interior Painter',
    'Exterior Painter',
    'Labourer',
    'Plasterer',
    'Wallpaper Installer',
    'Decorator/Interior Designer',
    'Color Consultant',
  ];

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const userId = getCookie('userId');

  useEffect(() => {
    if (userId) {
      fetchContractors();
    }
  }, [userId]);

  const fetchContractors = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/contractors`, {
        params: { userId }
      });
      console.log('Fetched contractors:', response.data);
      setContractors(response.data);
    } catch (error) {
      console.error('Error fetching contractors:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewContractor((prev) => {
      const updated = { ...prev, [name]: value };
      console.log('Updated newContractor:', updated);
      return updated;
    });
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setNewContractor((prev) => {
      const updated = { ...prev, [name]: newValue };
      return updated;
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhotoFile(file);
      setNewContractor((prev) => {
        const updated = { ...prev, photo: URL.createObjectURL(file) };
        console.log('Updated new Contractor with new photo:', updated);
        return updated;
      });
    }
  };

  /////////  HANDLE SUBMIT //////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!newContractor.fullName) {
      alert('Contractor Full Name is required');
      return;
    }
  
    if (!userId) {
      alert('User ID is missing. Please log in again.');
      return;
    }
  
    try {
      setIsUpdating(true);
      let contractorData = { ...newContractor, userId };
  
      // Handle photo upload first
      if (newPhotoFile) {
        const formData = new FormData();
        formData.append('contractorPhoto', newPhotoFile);
        formData.append('userId', userId);
        if (selectedContractorId) {
          formData.append('contractorId', selectedContractorId);
        }
  
        console.log('Uploading photo...');
        const photoResponse = await axios.post(`${API_ENDPOINT}/api/upload-contractor-photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Photo upload response:', photoResponse.data);
        contractorData.photo = photoResponse.data.photoUrl;
      }
  
      let response;
      if (selectedContractorId) {
        // Updating existing contractor
        console.log(`Updating contractor with ID: ${selectedContractorId}`);
        response = await axios.patch(`${API_ENDPOINT}/api/contractors/${selectedContractorId}`, contractorData);
      } else {
        // Creating new contractor
        console.log('Creating new contractor');
        response = await axios.post(`${API_ENDPOINT}/api/contractors`, contractorData);
      }
      console.log('Contractor save response:', response.data);
  
      await fetchContractors(); // Refresh the contractor list
  
      setIsUpdating(false);
      setUpdateSuccess(true);
      setNewPhotoFile(null);
      setTimeout(() => {
        setUpdateSuccess(false);
        setSelectedOption('list');
      }, 3000);
    } catch (error) {
      setIsUpdating(false);
      console.error('Error adding/updating contractor:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteContractor = async () => {
    if (!selectedContractorId) return;

    try {
      console.log(`Deleting contractor with ID: ${selectedContractorId}`);
      await axios.delete(`${API_ENDPOINT}/api/contractors/${selectedContractorId}`);
      await fetchContractors(); // Refresh the contractor list

      setNewContractor({
        fullName: '',
        email: '',
        phone: '',
        specialty: '',
        photo: DEFAULT_AVATAR,
        hourlyRate: 20,
        experience: 0,
      });
      setSelectedContractorId(null);
      setSelectedOption('list');
    } catch (error) {
      console.error('Error deleting contractor:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      if (newOption === 'add') {
        setSelectedContractorId(null);
        setNewContractor({
          fullName: '',
          email: '',
          phone: '',
          specialty: '',
          photo: DEFAULT_AVATAR,
          hourlyRate: 20,
          experience: 0,
        });
        setNewPhotoFile(null);
      }
    }
  };

  const handleContractorClick = (contractor) => {
    console.log('Selected contractor:', contractor);
    setSelectedContractorId(contractor._id);
    setNewContractor({
      fullName: contractor.fullName,
      email: contractor.email,
      phone: contractor.phone,
      specialty: contractor.specialty,
      photo: contractor.photo,
      hourlyRate: contractor.hourlyRate,
      experience: contractor.experience,
    });
    setNewPhotoFile(null);
    setSelectedOption('add');
  };

  const renderAddContractor = () => (
    <Box sx={theme.innerBox}>
      <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} gutterBottom>
          {selectedContractorId ? "Update Contractor Photo" : "Upload Contractor Photo"}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid grey',
            borderRadius: '8px',
            marginBottom: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
          onClick={() => document.getElementById('contractor-photo-input').click()}
        >
          <img
            src={newContractor.photo || DEFAULT_AVATAR}
            alt="Contractor"
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '6px', cursor: 'pointer'}}
          />
        </Box>
      </Box>
      <input
        id="contractor-photo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />
      
      <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }} gutterBottom>
          Contractor Details
        </Typography>
        <TextField
          label="Full Name"
          name="fullName"
          value={newContractor.fullName}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Email Address"
          name="email"
          value={newContractor.email}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Phone Number"
          name="phone"
          value={newContractor.phone}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth sx={theme.inputStyle}>
          <InputLabel id="specialty-label">Job</InputLabel>
          <Select
            labelId="specialty-label"
            id="specialty"
            name="specialty"
            value={newContractor.specialty}
            onChange={handleInputChange}
            label="Specialty"
            startAdornment={
              <InputAdornment position="start">
                <Work />
              </InputAdornment>
            }
          >
            {contractorTypes.map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ ...theme.inputStyle, mt: 2 }}>
          <Typography gutterBottom>
            Hourly Rate (${newContractor.hourlyRate})
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoney sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Slider
              value={newContractor.hourlyRate}
              onChange={handleSliderChange('hourlyRate')}
              aria-labelledby="hourly-rate-slider"
              valueLabelDisplay="auto"
              step={5}
              marks
              min={10}
              max={150}
            />
          </Box>
        </Box>

        <Box sx={{ ...theme.inputStyle, mt: 2 }}>
          <Typography gutterBottom>
            Experience ({newContractor.experience} years)
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Timeline sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <Slider
              value={newContractor.experience}
              onChange={handleSliderChange('experience')}
              aria-labelledby="experience-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={0}
              max={40}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating ? 'Saving...' : updateSuccess ? 'Contractor Saved' : selectedContractorId ? 'Update Contractor' : 'Add Contractor'}
          {updateSuccess && <Check style={{ marginLeft: '8px' }} />}
        </Button>
        {selectedContractorId && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteContractor}
            fullWidth
            sx={{ mt: 2 }}
          >
            Delete Contractor
          </Button>
        )}
      </Box>
    </Box>
  );

  const renderContractorList = () => (
    <Box p={0} sx={{ ...theme.innerBox, width: '100%' }}>
      <Box sx={theme.customFormBox}>
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif'  }} gutterBottom>Contractors</Typography>
      <Divider sx={{ marginLeft: 0 }} />
      <List>
        {Array.isArray(contractors) && contractors.length > 0 ? (
          contractors.map((contractor, index) => (
            <React.Fragment key={contractor._id}>
              <ListItem 
                onClick={() => handleContractorClick(contractor)}
                sx={theme.listItem}
              >
                <ListItemAvatar sx={{ minWidth: 'auto', marginRight:1 }}>
                  <Avatar 
                    src={contractor.photo || DEFAULT_AVATAR} 
                    sx={{
                      width: 64,
                      height: 64,
                      // border: '1px solid #000',
                      // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                      backgroundColor: '#f0f0f0',
                    }} 
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={<span style={theme.listItemTextName}>{contractor.fullName}</span>} 
                  secondary={<span style={theme.listItemText}>{`${contractor.specialty} | $${contractor.hourlyRate}/hr | ${contractor.experience} years exp.`}</span>}
                />
              </ListItem>
              {index < contractors.length - 1 && (
                <Divider component="li" sx={{ marginLeft: 0 }} />
              )}
            </React.Fragment>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No contractors added yet." />
          </ListItem>
        )}
      </List>
    </Box>
    </Box>
  );

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomToggleButton value="list"><RecentActors /></CustomToggleButton>
          <CustomToggleButton value="add"><Add /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      {selectedOption === 'add' ? renderAddContractor() : renderContractorList()}
    </Box>
  );
  
};

export default ContractorPanel;