import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, InputAdornment, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider } from '@mui/material';
import { Person, Mail, Phone, Home, LocationCity, LocationOn, Place, Public, Add, Check, Delete, RecentActors } from '@mui/icons-material';
import axios from 'axios';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import theme from './theme';

const ClientPanel = () => {
 
  
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newClient, setNewClient] = useState({
    clientFullName: '',
    clientEmail: '',
    clientPhone: '',
    clientStreetAddress: '',
    clientCity: '',
    clientState: '',
    clientPostalCode: '',
    clientCountry: '',
    clientPhoto: '/images/avatars/painter.webp',
    preferredContactMethod: '',
    notes: '',
    isActive: true,
    tags: [],
  });
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState('list');
  const [newPhotoFile, setNewPhotoFile] = useState(null);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const DEFAULT_AVATAR = '/images/avatars/painter.webp';

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const userId = getCookie('userId');

  useEffect(() => {
    if (userId) {
      fetchClients();
    }
  }, [userId]);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/clients`, {
        params: { userId }
      });
      setClients(response.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewClient((prev) => {
      const updated = { ...prev, [name]: value };
      console.log('Updated newClient:', updated);
      return updated;
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPhotoFile(file);
      setNewClient((prev) => {
        const updated = { ...prev, clientPhoto: URL.createObjectURL(file) };
        console.log('Updated newClient with new photo:', updated);
        return updated;
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!newClient.clientFullName) {
      alert('Client Full Name is required');
      return;
    }
  
    if (!userId) {
      alert('User ID is missing. Please log in again.');
      return;
    }
  
    try {
      setIsUpdating(true);
      let clientData = { ...newClient, userId };
  
      if (newPhotoFile) {
        const formData = new FormData();
        formData.append('clientPhoto', newPhotoFile);
        formData.append('userId', userId);
  
        console.log('Uploading photo...');
        const photoResponse = await axios.post(`${API_ENDPOINT}/api/upload-client-photo`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Photo upload response:', photoResponse.data);
        clientData.clientPhoto = photoResponse.data.photoUrl;
      }
  
      let response;
      if (selectedClientId) {
        console.log(`Updating client with ID: ${selectedClientId}`);
        response = await axios.patch(`${API_ENDPOINT}/api/clients/${selectedClientId}`, clientData);
      } else {
        console.log('Creating new client');
        response = await axios.post(`${API_ENDPOINT}/api/clients`, clientData);
      }
      console.log('Client save response:', response.data);
  
      await fetchClients(); // Refresh the client list
  
      setIsUpdating(false);
      setUpdateSuccess(true);
      setNewPhotoFile(null);
      setTimeout(() => {
        setUpdateSuccess(false);
        setSelectedOption('list');
      }, 3000);
    } catch (error) {
      setIsUpdating(false);
      console.error('Error adding/updating client:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteClient = async () => {
    if (!selectedClientId) return;

    try {
      console.log(`Deleting client with ID: ${selectedClientId}`);
      await axios.delete(`${API_ENDPOINT}/api/clients/${selectedClientId}`);
      await fetchClients(); // Refresh the client list

      setNewClient({
        clientFullName: '',
        clientEmail: '',
        clientPhone: '',
        clientStreetAddress: '',
        clientCity: '',
        clientState: '',
        clientPostalCode: '',
        clientCountry: '',
        clientPhoto: DEFAULT_AVATAR,
        preferredContactMethod: '',
        notes: '',
        isActive: true,
        tags: [],
      });
      setSelectedClientId(null);
      setSelectedOption('list');
    } catch (error) {
      console.error('Error deleting client:', error);
      alert(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
      if (newOption === 'add') {
        setSelectedClientId(null);
        setNewClient({
          clientFullName: '',
          clientEmail: '',
          clientPhone: '',
          clientStreetAddress: '',
          clientCity: '',
          clientState: '',
          clientPostalCode: '',
          clientCountry: '',
          clientPhoto: DEFAULT_AVATAR,
          preferredContactMethod: '',
          notes: '',
          isActive: true,
          tags: [],
        });
        setNewPhotoFile(null);
      }
    }
  };

  const handleClientClick = (client) => {
    console.log('Selected client:', client);
    setSelectedClientId(client._id);
    setNewClient({
      clientFullName: client.clientFullName,
      clientEmail: client.clientEmail,
      clientPhone: client.clientPhone,
      clientStreetAddress: client.clientStreetAddress,
      clientCity: client.clientCity,
      clientState: client.clientState,
      clientPostalCode: client.clientPostalCode,
      clientCountry: client.clientCountry,
      clientPhoto: client.clientPhoto,
      preferredContactMethod: client.preferredContactMethod,
      notes: client.notes,
      isActive: client.isActive,
      tags: client.tags,
    });
    setNewPhotoFile(null);
    setSelectedOption('add');
  };

  const renderAddClient = () => (
    <Box sx={theme.innerBox}>
      <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif' }} gutterBottom>
          {selectedClientId ? "Update Client Photo" : "Upload Client Photo"}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '2px solid grey',
            borderRadius: '8px',
            marginBottom: 1,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
          onClick={() => document.getElementById('client-photo-input').click()}
        >
          <img
            src={newClient.clientPhoto || DEFAULT_AVATAR}
            alt="Client"
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '6px', cursor: 'pointer'}}
          />
        </Box>
      </Box>
      <input
        id="client-photo-input"
        type="file"
        hidden
        onChange={handleImageUpload}
      />
      
      <Box sx={theme.customFormBox}>
         <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem',fontFamily: '"Figtree", sans-serif' }} gutterBottom>Contact Details</Typography>
                <TextField
                  label="Full Name"
                  name="clientFullName"
                  value={newClient.clientFullName}
                  onChange={handleInputChange}
                  fullWidth
                  sx={theme.inputStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Email Address"
                  name="clientEmail"
                  value={newClient.clientEmail}
                  onChange={handleInputChange}
                  fullWidth
                  sx={theme.inputStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Phone Number"
                  name="clientPhone"
                  value={newClient.clientPhone}
                  onChange={handleInputChange}
                  fullWidth
                  sx={theme.inputStyle}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone />
                      </InputAdornment>
                    ),
                  }}
                />
        </Box>

        <Box sx={theme.customFormBox}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', fontFamily: '"Figtree", sans-serif'  }} gutterBottom>Address Details</Typography>
          <TextField
          label="Street Address"
          name="clientStreetAddress"
          value={newClient.clientStreetAddress}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Home />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="City"
          name="clientCity"
          value={newClient.clientCity}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCity />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="State/Province"
          name="clientState"
          value={newClient.clientState}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Postal/ZIP Code"
          name="clientPostalCode"
          value={newClient.clientPostalCode}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Place />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Country"
          name="clientCountry"
          value={newClient.clientCountry}
          onChange={handleInputChange}
          fullWidth
          sx={theme.inputStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Public />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color={updateSuccess ? 'success' : 'primary'}
          onClick={handleSubmit}
          fullWidth
          sx={{ mt: 2, ...(updateSuccess && theme.successButton) }}
          disabled={isUpdating}
        >
          {isUpdating ? 'Saving...' : updateSuccess ? 'Client Saved' : selectedClientId ? 'Update Client' : 'Add Client'}
          {updateSuccess && <Check style={{ marginLeft: '8px' }} />}
        </Button>
        {selectedClientId && (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteClient}
            fullWidth
            sx={{ mt: 2 }}
          >
            Delete Client
          </Button>
        )}</Box>
      </Box>
    
  );

  const renderClientList = () => (
    <Box p={0} sx={{ ...theme.innerBox, width: '100%' }}>
      <Box sx={theme.customFormBox}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.4rem', fontFamily: '"Figtree", sans-serif'  }} gutterBottom>Client List</Typography>
        <Divider sx={{ marginLeft: 0 }} />
        <List>
          {isLoading ? (
            <ListItem>
              <ListItemText primary="Loading clients..." />
            </ListItem>
          ) : Array.isArray(clients) && clients.length > 0 ? (
            clients.map((client, index) => (
              <React.Fragment key={client._id}>
                <ListItem 
                  onClick={() => handleClientClick(client)}
                  sx={theme.listItem}
                >
                  <ListItemAvatar sx={{ minWidth: 'auto', marginRight:1 }}>
                    <Avatar 
                      src={client.clientPhoto || DEFAULT_AVATAR} 
                      sx={{
                        width: 64,
                        height: 64,
                        backgroundColor: '#f0f0f0',
                      }} 
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<span style={theme.listItemTextName}>{client.clientFullName}</span>} 
                    secondary={<span style={theme.listItemText}>{`${client.clientEmail} ${client.clientPhone}`}</span>}
                  />
                </ListItem>
                {index < clients.length - 1 && (
                  <Divider component="li" sx={{ marginLeft: 0 }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No clients added yet." />
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomToggleButton value="list"><RecentActors /></CustomToggleButton>
          <CustomToggleButton value="add"><Add /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      {selectedOption === 'add' ? renderAddClient() : renderClientList()}
    </Box>
  );
};

export default ClientPanel;