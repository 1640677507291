import React, { useState, useEffect, useRef } from 'react';
import { AddAPhoto, Assessment } from '@mui/icons-material';
import { Box, Typography, Tooltip, tooltipClasses, TextField, MenuItem, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import CheckIcon from '@mui/icons-material/Check';
import theme from './theme'; 

const inputStyles = { fontSize: '14px', padding: '2px' };

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: theme.customTooltip,
}));

const UploadButton = styled(Button)(theme.uploadButton);

const Canvas = styled('canvas')(theme.canvas);

const PositionedActionButton = styled(Button)(theme.positionedActionButton);

const InspectionOptions = ({ rooms = [], selectedRoomId, setSelectedRoomId, setInspectionReport }) => {
  const [selectedOption, setSelectedOption] = useState('photos');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outputImageUrl, setOutputImageUrl] = useState(null);
  const [error, setError] = useState(null);

  const inputCanvasRef = useRef(null);

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  const handleRoomChange = (event) => {
    setSelectedRoomId(event.target.value);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    setOutputImageUrl(null);
    setError(null);
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const handleGenerate = async () => {
    if (!image) return;

    const formData = new FormData();
    formData.append('image', image);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/generate-inspection-report`, formData);
      console.log('Report generated successfully:', response.data);
      setInspectionReport(response.data.detailed_inspection_report); // This should work now
    } catch (err) {
      console.error('Error generating report:', err);
      setError('Error generating report');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (image) {
      const inputCtx = inputCanvasRef.current.getContext('2d');
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        const canvasWidth = inputCanvasRef.current.clientWidth;
        const canvasHeight = canvasWidth / aspectRatio;
        inputCanvasRef.current.width = canvasWidth;
        inputCanvasRef.current.height = canvasHeight;
        inputCtx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
      };
    }
  }, [image]);

  const renderInspectionOptions = () => {
    return (
      <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Box>
        <TextField
          select
          fullWidth
          label="Select Room"
          value={selectedRoomId}
          onChange={handleRoomChange}
          margin="normal"
          InputProps={{ style: inputStyles }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
        >
          {rooms.map((room) => (
            <MenuItem key={room.id} value={room.id}>
              {room.name}
            </MenuItem>
          ))}
        </TextField>
        <Box
          mt={2}
          position="relative"
          width="90%"
          textAlign="center"
          style={{ backgroundColor: 'rgba(200, 200, 200, 1)', padding: '10px', borderRadius: '8px' }}
        >
          <Canvas ref={inputCanvasRef} style={{ outlineStyle: 'dashed', outlineColor: 'rgba(0, 0, 0, 0.3)', maxWidth: '100%', height: 'auto' }} />
          <PositionedActionButton 
            variant="contained" 
            component={image ? 'button' : 'label'} 
            onClick={image ? handleGenerate : null} 
            disabled={loading}
            sx={{ 
              bottom: image ? '10px' : '10%', 
              transform: image ? 'translateY(0)' : 'translateY(10%)',
            }}
          >
            {loading ? <CircularProgress size={24} /> : (outputImageUrl ? <CheckIcon /> : image ? 'Generate Report' : 'Upload Image')}
            {!image && (
              <input type="file" accept="image/*" onChange={handleImageChange} required hidden />
            )}
          </PositionedActionButton>
        </Box>
        {error && <Typography color="error" mt={2}>{error}</Typography>}
      </Box>
    </Box>
    );
  };

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
          <CustomToggleButton value="photos"><AddAPhoto /></CustomToggleButton>
          <CustomToggleButton value="report"><Assessment /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box sx={theme.innerBox}>
        {renderInspectionOptions()}
      </Box>
    </Box>
  );
};

export default InspectionOptions;
