import React, { useState, useEffect } from 'react';
import {ViewQuilt, ColorLens, FormatPaint, AlignVerticalTop, CheckBoxOutlineBlank, AlignHorizontalLeft, AlignHorizontalRight, AlignVerticalBottom } from '@mui/icons-material';
import { Box, Typography, Tooltip, tooltipClasses, TextField, Divider } from '@mui/material';
import { styled } from '@mui/system';
import theme from './theme'; 

import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import { PIXELS_PER_METER } from './components/Floorplan/Constants';

const inputStyles = { fontSize: '18px', padding: '2px' };

const CustomTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '24px',
    padding: '8px',
  },
}));

const SwatchBox = styled(Box)(({ selected }) => ({
  width: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '3px',
  borderRadius: '5px',
  backgroundColor: '#fff',
  margin: '2px 2px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s, box-shadow 0.2s, border 0.2s',
  transform: selected ? 'scale(0.9)' : 'none',
  border: selected ? '2px solid black' : 'none',
  '&:hover': {
    transform: 'scale(1.1)',
    border: '1px solid black',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
}));

const SwatchColorBox = styled(Box)({
  width: '95px',
  height: '95px',
  borderRadius: '5px',
  padding: '3px',
  marginBottom: '15px',
  position: 'relative',
});

const SwatchName = styled(Typography)({
  fontSize: '12px',
  textAlign: 'center',
});

const RoomOptions = ({
  roomName, setRoomName, roomLength, setRoomLength, roomWidth, setRoomWidth,
  paintBrandRoom, setPaintBrandRoom, paintHueRoom, setPaintHueRoom,
  paintColor, setPaintColor, skirtingBoards, setSkirtingBoards,
  architrave, setArchitrave, windows, setWindows, doors, setDoors,
  ceiling, setCeiling, paintFinish, setPaintFinish, style, handleStyleChange,
  rooms, setRooms, selectedRoomId, setSelectedColor
}) => {
  const [selectedOption, setSelectedOption] = useState('colour');
  const [selectedSwatch, setSelectedSwatch] = useState(null);

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  useEffect(() => {
    if (selectedRoomId) {
      const selectedRoom = rooms.find(room => room.id === selectedRoomId);
      if (selectedRoom) {
        setRoomWidth((selectedRoom.width / PIXELS_PER_METER).toFixed(1));
        setRoomLength((selectedRoom.length / PIXELS_PER_METER).toFixed(1));
        setRoomName(selectedRoom.name);
      }
    }
  }, [selectedRoomId, rooms]);

  const handleChange = (name) => (e) => {
    const value = e.target.value;

    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === selectedRoomId
          ? {
              ...room,
              [name]: name === 'name' 
                ? value 
                : parseFloat(value) * PIXELS_PER_METER,
            }
          : room
      )
    );
  };

  const handleSwatchClick = (color, wall) => {
    setSelectedColor(color);
    setSelectedSwatch(wall);
    
    // Update the room's wall color
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room.id === selectedRoomId
          ? {
              ...room,
              walls: {
                ...room.walls,
                [wall]: {
                  ...room.walls[wall],
                  color: color,
                },
              },
            }
          : room
      )
    );
  };

  const renderOptionInputs = () => {
    switch (selectedOption) {
      case 'paint':
        return (
          <Box>
            <TextField
              fullWidth
              label="Paint Brand"
              value={paintBrandRoom}
              onChange={(e) => setPaintBrandRoom(e.target.value)}
              margin="normal"
              sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
              InputLabelProps={{ style: { fontSize: '16px' } }}
            />
            <TextField
              fullWidth
              label="Paint Type"
              value={paintHueRoom}
              onChange={(e) => setPaintHueRoom(e.target.value)}
              margin="normal"
              sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
              InputLabelProps={{ style: { fontSize: '16px' } }}
            />
            <TextField
              fullWidth
              label="Paint Size"
              value={paintColor}
              onChange={(e) => setPaintColor(e.target.value)}
              margin="normal"
              sx={{ ...theme.inputStyle, '& .MuiOutlinedInput-root': { border: 'none' } }}
              InputLabelProps={{ style: { fontSize: '16px' } }}
            />
          </Box>
        );
      case 'colour':
        const selectedRoom = rooms.find(room => room.id === selectedRoomId);
        if (!selectedRoom) return null;

        const wallColors = [
          { name: 'Left Wall', color: selectedRoom.walls.left.color || '#FFFFFF', wall: 'left' },
          { name: 'Right Wall', color: selectedRoom.walls.right.color || '#FFFFFF', wall: 'right' },
          { name: 'Top Wall', color: selectedRoom.walls.top.color || '#FFFFFF', wall: 'top' },
          { name: 'Bottom Wall', color: selectedRoom.walls.bottom.color || '#FFFFFF', wall: 'bottom' },
        ];

        return (
          <Box sx={{ mb: 4, border: '0px solid #fff', borderRadius: '0px', p: 0, backgroundColor: 'rgba(255, 255, 255, 0)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.0)' }}>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={1} mt={2}>
              {wallColors.map((wallColor, index) => (
                <SwatchBox 
                  key={index} 
                  // onClick={() => handleSwatchClick(wallColor.color, wallColor.wall)} 
                  selected={selectedSwatch === wallColor.wall}
                >
                  <SwatchColorBox bgcolor={wallColor.color} />
                  <SwatchName>{wallColor.name}</SwatchName>
                </SwatchBox>
              ))}
            </Box>
          </Box>
        );
        case 'slant':
          return (
            <Box my={2}>
              <Typography variant="subtitle1" gutterBottom>Number of windows</Typography>
              <CustomToggleButtonGroup value={windows} exclusive onChange={(e, value) => setWindows(value)} fullWidth>
                <CustomTooltip title="One">
                  <CustomToggleButton value={1}>One</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Two">
                  <CustomToggleButton value={2}>Two</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Three">
                  <CustomToggleButton value={3}>Three</CustomToggleButton>
                </CustomTooltip>
              </CustomToggleButtonGroup>
              
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Number of doors</Typography>
              <CustomToggleButtonGroup value={1} exclusive onChange={(e, value) => setDoors(value)} fullWidth>
                <CustomTooltip title="Small">
                  <CustomToggleButton value="1">One</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Medium">
                  <CustomToggleButton value="2">Two</CustomToggleButton>
                </CustomTooltip>
                <CustomTooltip title="Large">
                  <CustomToggleButton value="3">Three</CustomToggleButton>
                </CustomTooltip>
              </CustomToggleButtonGroup>
            </Box>
          );
     
      default:
        return null;
    }
  };

  return (
    <Box p={0} borderRadius="0px" sx={{ backgroundColor: 'rgba(255, 255, 255, 0)', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)' }}>
      <Box my={0}>
        <CustomToggleButtonGroup value={selectedOption} exclusive onChange={handleOptionChange} fullWidth>
        <CustomToggleButton value="colour"><ColorLens /></CustomToggleButton>
        <CustomToggleButton value="paint"><FormatPaint /></CustomToggleButton>
         <CustomToggleButton value="slant"><ViewQuilt /></CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box sx={theme.innerBox}>
      {/* RoomOptions */}
      <Box sx={{ mb: 3, p: 2, border: '1px solid #fff', borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <TextField
          fullWidth
          label="Room Name"
          value={roomName}
          onChange={handleChange('name')}
          margin="normal"
          InputProps={{ style: inputStyles }}
          InputLabelProps={{ style: { fontSize: '14px' } }}
        />

        <Typography gutterBottom style={{ fontSize: '12px' }}>Dimensions (meters)</Typography>
        <Box display="flex" justifyContent="space-between">
        <TextField
            label="Width"
            type="number"
            value={roomWidth}
            onChange={handleChange('width')}
            margin="normal"
            style={{ width: '45%' }}
            InputProps={{ 
              style: inputStyles,
              inputProps: { 
                step: 0.1,
                min: 0,
              } 
            }}
            InputLabelProps={{ style: { fontSize: '12px' } }}
          />
          <TextField
            label="Length"
            type="number"
            value={roomLength}
            onChange={handleChange('length')}
            margin="normal"
            style={{ width: '45%' }}
            InputProps={{ 
              style: inputStyles,
              inputProps: { 
                step: 0.1,
                min: 0,
              } 
            }}
            InputLabelProps={{ style: { fontSize: '14px' } }}
          />
        </Box>
        <Divider  sx={{ marginLeft: 0 }} />
        <Box my={2}>
          <CustomToggleButtonGroup value={style} exclusive onChange={handleStyleChange} fullWidth>
            <CustomTooltip title="All Walls">
              <CustomToggleButton value="allWalls"><CheckBoxOutlineBlank /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Left Wall">
              <CustomToggleButton value="left"><AlignHorizontalLeft /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Top Wall">
              <CustomToggleButton value="top"><AlignVerticalTop /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Bottom Wall">
              <CustomToggleButton value="bottom"><AlignVerticalBottom /></CustomToggleButton>
            </CustomTooltip>
            <CustomTooltip title="Right Wall">
              <CustomToggleButton value="right"><AlignHorizontalRight /></CustomToggleButton>
            </CustomTooltip>
          </CustomToggleButtonGroup>
        </Box>
      {renderOptionInputs()}
      </Box>
      </Box>
    </Box>
  );
};

export default RoomOptions;