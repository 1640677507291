import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Stage, Layer } from 'react-konva';
import Room from './Room';
import NewRoom from './NewRoom';
import { SwatchBox, SwatchColorBox, SwatchHex, SwatchName } from '../../styles';
import { PIXELS_PER_METER } from './Constants';
import axios from 'axios';

const ZoomControls = ({ zoom, handleZoomChange, handleDeleteRoom, selectedRoomId, floorNames }) => {
  const deleteButtonStyle = {
    marginLeft: '20px',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor: selectedRoomId ? 'pointer' : 'not-allowed',
    backgroundColor: selectedRoomId ? '#ff4d4d' : '#ffb3b3',
    opacity: selectedRoomId ? 1 : 0.6,
    transition: 'all 0.3s ease'
  };

  const saveButtonStyle = {
    marginLeft: '20px',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '4px',
    cursor:  'pointer',
    backgroundColor: 'green',
    
    transition: 'all 0.3s ease'
  };

  const [selectedFloorName, setSelectedFloorName] = useState(floorNames[0] || '');

  const handleFloorChange = (event) => {
    const newFloorName = event.target.value;
    setSelectedFloorName(newFloorName);
    localStorage.setItem('selectedFloorName', newFloorName);
  };

  const selectStyle = {
    marginRight: '10px',
    padding: '5px',
    borderRadius: '4px',
    border: '1px solid #ccc'
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '60%',
      transform: 'translateX(-60%)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      width: '50%', // Increased width to accommodate new elements
      justifyContent: 'space-between' // Distribute space evenly
    }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <select 
          style={selectStyle} 
          value={selectedFloorName}
          onChange={handleFloorChange}
        >
          {floorNames.map((floorName, index) => (
            <option key={index} value={floorName}>{floorName}</option>
          ))}
        </select>
       
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: 'center' }}>
        <button onClick={() => handleZoomChange(Math.max(zoom - 10, 10))}>-</button>
        <input
          type="range"
          min="25"
          max="200"
          value={zoom}
          onChange={(e) => handleZoomChange(Number(e.target.value))}
          style={{ margin: '0 10px', width: '200px' }}
        />
        <button onClick={() => handleZoomChange(Math.min(zoom + 10, 200))}>+</button>
        <span style={{ marginLeft: '10px' }}>{zoom}%</span>
        <button 
          onClick={() => handleZoomChange(100)} 
          style={{ marginLeft: '10px' }}
        >
          Fit
        </button>
      </div>
      <button 
        onClick={handleDeleteRoom} 
        style={deleteButtonStyle}
        disabled={!selectedRoomId}
      >
        Delete Room
      </button>
      <button 
        
        style={saveButtonStyle}
      >
        Save Floorplan
      </button>
    </div>
  );
};


const FloorPlan = ({
  rooms,
  setRooms,
  newRoom,
  setNewRoom,
  selectedRoomId,
  setSelectedRoomId,
  selectedColor,
  setSelectedColor,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [draggingRoomId, setDraggingRoomId] = useState(null);
  const [resizing, setResizing] = useState({ isResizing: false, roomId: null, handle: null });
  const [hoveringHandle, setHoveringHandle] = useState({ roomId: null, handle: null });
  const [selectedWall, setSelectedWall] = useState({ roomId: null, wall: null });
  const [hoveredSwatch, setHoveredSwatch] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(100);

  const roomsRef = useRef(rooms);
  const [isInitialLayoutSet, setIsInitialLayoutSet] = useState(false);

  const sortRoomsAlphabetically = (rooms) => {
    return [...rooms].sort((a, b) => a.name.localeCompare(b.name));
  };

  const calculateBoundingBox = (rooms) => {
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    rooms.forEach(room => {
      minX = Math.min(minX, room.x);
      minY = Math.min(minY, room.y);
      maxX = Math.max(maxX, room.x + room.width);
      maxY = Math.max(maxY, room.y + room.length);
    });
    return { minX, minY, maxX, maxY };
  };

  const adjustRoomPositions = (rooms, boundingBox) => {
    const padding = 20; // Padding in pixels
    const offsetX = -boundingBox.minX + padding;
    const offsetY = -boundingBox.minY + padding;
    
    return rooms.map(room => ({
      ...room,
      x: room.x + offsetX,
      y: room.y + offsetY
    }));
  };

  useEffect(() => {
    roomsRef.current = rooms;
  }, [rooms]);

  const updateLocalStorage = (updatedRooms) => {
    const storedJobData = JSON.parse(localStorage.getItem('jobData'));
    
    if (storedJobData && storedJobData.FloorPlanID) {
      const selectedFloorName = localStorage.getItem('selectedFloorName') || storedJobData.FloorPlanID.Floors[0].Name;
      
      const floorIndex = storedJobData.FloorPlanID.Floors.findIndex(floor => floor.Name === selectedFloorName);
      
      if (floorIndex !== -1) {
        storedJobData.FloorPlanID.Floors[floorIndex].rooms = updatedRooms;
        localStorage.setItem('jobData', JSON.stringify(storedJobData));
      }
    }
  };

  const stageRef = useRef(null);

  const [floorNames, setFloorNames] = useState(['Floor 1', 'Floor 2', 'Floor 3']); 

  useEffect(() => {
    const storedJobData = JSON.parse(localStorage.getItem('jobData'));
    if (storedJobData && storedJobData.FloorPlanID && storedJobData.FloorPlanID.Floors) {
      const newFloorNames = storedJobData.FloorPlanID.Floors.map(floor => floor.Name);
      setFloorNames(newFloorNames);
    }
  }, []); 

  const snapToGrid = useCallback((value) => {
    const gridSize = 0.1 * PIXELS_PER_METER; // 0.1m in pixels
    return Math.round(value / gridSize) * gridSize;
  }, []);

  const getMousePosition = useCallback((stage) => {
    const mousePos = stage.getPointerPosition();
    return {
      x: mousePos.x / stage.scaleX(),
      y: mousePos.y / stage.scaleY()
    };
  }, []);

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
    const newScale = newZoom / 100;
    stageRef.current.scale({ x: newScale, y: newScale });
  
    if (newZoom === 100) { // If "Fit" button is clicked
      const boundingBox = calculateBoundingBox(rooms);
      const padding = 40; // Extra padding
      const stageWidth = window.innerWidth * 1.0;
      const stageHeight = window.innerHeight * 3.0;
      const scaleX = stageWidth / (boundingBox.maxX - boundingBox.minX + padding * 2);
      const scaleY = stageHeight / (boundingBox.maxY - boundingBox.minY + padding * 2);
      const scale = Math.min(scaleX, scaleY);
      
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.position({
        x: (stageWidth - (boundingBox.maxX - boundingBox.minX) * scale) / 2 - boundingBox.minX * scale + padding * scale,
        y: (stageHeight - (boundingBox.maxY - boundingBox.minY) * scale) / 2 - boundingBox.minY * scale + padding * scale
      });
    }
  
    stageRef.current.batchDraw();
  };

  const handleDeleteRoom = () => {
    if (selectedRoomId) {
      const updatedRooms = rooms.filter(room => room.id !== selectedRoomId);
      setRooms(updatedRooms);
      updateLocalStorage(updatedRooms);
      setSelectedRoomId(null);
      setSelectedWall({ roomId: null, wall: null });
    }
  };

  const setInitialLayout = (rooms) => {
    // Check if rooms already have positions
    const needsLayout = rooms.some(room => room.x === undefined || room.y === undefined);
    
    if (!needsLayout) {
      return rooms;
    }
  
    const sortedRooms = [...rooms].sort((a, b) => a.name.localeCompare(b.name));
    const padding = 20;
    let currentX = padding;
    let currentY = padding;
    let maxHeightInRow = 0;
  
    return sortedRooms.map((room) => {
      if (currentX + room.width > window.innerWidth - padding) {
        currentX = padding;
        currentY += maxHeightInRow + padding;
        maxHeightInRow = 0;
      }
  
      const newRoom = {
        ...room,
        x: room.x !== undefined ? room.x : currentX,
        y: room.y !== undefined ? room.y : currentY
      };
  
      currentX += room.width + padding;
      maxHeightInRow = Math.max(maxHeightInRow, room.length);
  
      return newRoom;
    });
  };

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const loadRooms = () => {
      const storedJobData = JSON.parse(localStorage.getItem('jobData'));
  
      if (storedJobData && storedJobData.FloorPlanID) {
        const selectedFloorName = localStorage.getItem('selectedFloorName') || storedJobData.FloorPlanID.Floors[0].Name;
        const selectedFloor = storedJobData.FloorPlanID.Floors.find(floor => floor.Name === selectedFloorName);
        
        if (selectedFloor && selectedFloor.rooms && selectedFloor.rooms.length > 0) {
          setRooms(selectedFloor.rooms);
          return;
        }
      }
  
      // If we reach here, it means we don't have any rooms in localStorage
      fetchRoomsFromAPI();
    };
  
    const fetchRoomsFromAPI = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/rooms`);
        const initialRooms = setInitialLayout(response.data);
        setRooms(initialRooms);
        updateLocalStorage(initialRooms);
      } catch (error) {
        console.error('Error fetching rooms:', error);
      }
    };
  
    loadRooms();
  }, []);


  const addRoom = async (newRoom) => {
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/rooms`, newRoom);
      
      const updatedRooms = [...rooms, response.data];
      setRooms(updatedRooms);
      updateLocalStorage(updatedRooms);
    } catch (error) {
      console.error('Error adding room:', error);
    }
  };

  useEffect(() => {
    if (selectedColor && selectedWall.roomId && selectedWall.wall) {
      const updatedRooms = rooms.map((room) => {
        if (room.id === selectedWall.roomId) {
          return {
            ...room,
            walls: {
              ...room.walls,
              [selectedWall.wall]: {
                ...room.walls[selectedWall.wall],
                color: selectedColor,
              },
            },
          };
        }
        return room;
      });
  
      setRooms(updatedRooms);
      updateLocalStorage(updatedRooms); // Ensure local storage is updated with the new color
    }
  }, [selectedColor, selectedWall, rooms, setRooms]);

  const handleMouseDown = (e) => {
    const target = e.target;
    if (target.name() === 'room' || target.name() === 'handle' || target.name() === 'text') {
      return;
    }
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
    setIsDrawing(true);
    const newId = `room-${rooms.length}`;
    const newName = `Room ${rooms.length + 1}`;
    setNewRoom({
      id: newId,
      x: snapToGrid(mousePos.x),
      y: snapToGrid(mousePos.y),
      width: 0,
      length: 0,
      name: newName,
      walls: { top: {}, bottom: {}, left: {}, right: {} }
    });
 };


  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePosition(stage);
    setMousePosition(mousePos);
    if (isDrawing) {
      const width = snapToGrid(mousePos.x - newRoom.x);
      const length = snapToGrid(mousePos.y - newRoom.y);
      setNewRoom({
        ...newRoom,
        width,
        length,
      });
      
    } else if (resizing.isResizing) {
      const { roomId, handle } = resizing;
      const roomIndex = rooms.findIndex((room) => room.id === roomId);
      const newRooms = rooms.slice();
      const room = newRooms[roomIndex];

      const snappedX = snapToGrid(mousePos.x);
      const snappedY = snapToGrid(mousePos.y);

      setRooms(newRooms);
      // updateLocalStorage(newRooms);

      switch (handle) {
        case 'top-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.x = snappedX;
          room.y = snappedY;
          break;
        case 'top-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(room.length + room.y - snappedY);
          room.y = snappedY;
          break;
        case 'bottom-left':
          room.width = snapToGrid(room.width + room.x - snappedX);
          room.x = snappedX;
          room.length = snapToGrid(snappedY - room.y);
          break;
        case 'bottom-right':
          room.width = snapToGrid(snappedX - room.x);
          room.length = snapToGrid(snappedY - room.y);
          break;
        default:
          break;
      }

      setRooms(newRooms);
      // updateLocalStorage(newRooms);
    }
  };

  const handleMouseUp = () => {
    if (isDrawing && newRoom) {
      if (Math.abs(newRoom.width) >= PIXELS_PER_METER * 2 && Math.abs(newRoom.length) >= PIXELS_PER_METER * 2) {
        setRooms((prevRooms) => [...prevRooms, newRoom]);
        
      }
    }
    setIsDrawing(false);
    setNewRoom(null);
    setResizing({ isResizing: false, roomId: null, handle: null });
  };

  const handleDragStart = useCallback((id) => {
    setDraggingRoomId(id);
  }, []);

  const handleDragMove = useCallback((room, e) => {
    const { x, y } = e.target.position();
    const snappedX = snapToGrid(x);
    const snappedY = snapToGrid(y);

    setRooms(prevRooms => prevRooms.map(r => 
      r.id === room.id
        ? { ...r, x: snappedX, y: snappedY }
        : r
    ));

    // Force update on the specific room's Konva node
    e.target.position({ x: snappedX, y: snappedY });
    e.target.getLayer().batchDraw();
  }, [snapToGrid, setRooms]);

  const updateDatabase = async (updatedRooms) => {
    try {
      await axios.put(`${API_ENDPOINT}/api/rooms`, updatedRooms);
    } catch (error) {
      console.error('Error updating rooms in database:', error);
    }
  };

  const handleDragEnd = useCallback((room, e) => {
    setDraggingRoomId(null);
    const { x, y } = e.target.position();
    const snappedX = snapToGrid(x);
    const snappedY = snapToGrid(y);
  
    setRooms(prevRooms => {
      const newRooms = prevRooms.map(r => 
        r.id === room.id
          ? { ...r, x: snappedX, y: snappedY }
          : r
      );
      updateLocalStorage(newRooms);

      updateDatabase(newRooms);
      return newRooms;
    });
  }, [snapToGrid]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      updateLocalStorage(rooms);
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rooms]);

  const handleRoomClick = useCallback((id) => {
    setSelectedRoomId(id);
    setSelectedWall({ roomId: null, wall: null });
  }, []);

  const handleWallClick = useCallback((roomId, wall) => {
    const room = rooms.find((r) => r.id === roomId);
    if (room) {
      if (!room.walls) {
        room.walls = { top: {}, bottom: {}, left: {}, right: {} };
      }
      setSelectedWall({ roomId, wall });
      setSelectedRoomId(roomId);
    }
  }, [rooms]);

  const handleMouseEnter = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'move';
    if (e.target.name() === 'room') {
      setHoveringHandle({ roomId: e.target.attrs.id, handle: null });
    }
  };

  const handleMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseEnter = (e, roomId, handle) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'nwse-resize';
    setHoveringHandle({ roomId, handle });
  };

  const handleResizeMouseLeave = (e) => {
    const stage = e.target.getStage();
    stage.container().style.cursor = 'default';
    setHoveringHandle({ roomId: null, handle: null });
  };

  const handleResizeMouseDown = (roomId, handle) => {
    setResizing({ isResizing: true, roomId, handle });
  };

  const handleStageClick = (e) => {
    const target = e.target;
    if (target === stageRef.current) {
      setSelectedRoomId(null);
      setSelectedWall({ roomId: null, wall: null });
    }
  };


  // Memoize the rooms array to prevent unnecessary re-renders
  const memoizedRooms = useMemo(() => rooms, [rooms]);

  return (
    <div style={{ position: 'relative', paddingBottom: '25%' }}>
      
      <Stage
        ref={stageRef}
        width={window.innerWidth * 1.0}
        height={window.innerHeight * 3.0}
        scaleX={zoom / 100}
        scaleY={zoom / 100}
        onMouseDown={(e) => {
          handleMouseDown(e);
          handleStageClick(e);
        }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer>
          {memoizedRooms.map((room) => (
            <Room
              key={room.id}
              room={room}
              selectedRoomId={selectedRoomId}
              draggingRoomId={draggingRoomId}
              selectedWall={selectedWall}
              handleDragStart={handleDragStart}
              handleDragMove={handleDragMove}
              handleDragEnd={handleDragEnd}
              handleRoomClick={handleRoomClick}
              handleWallClick={handleWallClick}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              handleResizeMouseEnter={handleResizeMouseEnter}
              handleResizeMouseLeave={handleResizeMouseLeave}
              handleResizeMouseDown={handleResizeMouseDown}
              onWallHover={setHoveredSwatch}
            />
          ))}
          <NewRoom newRoom={newRoom} />
        </Layer>
      </Stage>
     
      {/* {hoveredSwatch && (
        <SwatchBox style={{ position: 'absolute', left: mousePosition.x - 100, top: mousePosition.y }}>
          <SwatchColorBox bgcolor={hoveredSwatch.color}>
            <SwatchHex>{hoveredSwatch.hexValue}</SwatchHex>
          </SwatchColorBox>
          <SwatchName>{hoveredSwatch.name}</SwatchName>
        </SwatchBox>
      )} */}

      <ZoomControls 
        floorNames={floorNames}
        zoom={zoom} 
        handleZoomChange={handleZoomChange} 
        handleDeleteRoom={handleDeleteRoom}
        selectedRoomId={selectedRoomId}
      />
    </div>
  );
};

export default FloorPlan;