import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setToken } from './utils/auth';
import { 
  Box, 
  Grid, 
  TextField, 
  Button, 
  Typography, 
  Paper 
} from '@mui/material';
import palettesData from './login_palettes.json';

const Login = () => {
  const [palette, setPalette] = useState(null);
  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    const randomPalette = palettesData.palettes[Math.floor(Math.random() * palettesData.palettes.length)];
    setPalette(randomPalette);
  }, []);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const initializeAppData = async (userId) => {
    try {
      // Fetch Account Data
      const accountResponse = await axios.get(`${API_ENDPOINT}/api/accounts/user/${userId}`);
      const accountData = accountResponse.data;

      // Fetch Clients
      const clientsResponse = await axios.get(`${API_ENDPOINT}/api/clients`);
      const clientsData = clientsResponse.data.filter(client => client.userId === userId);

      // Fetch Contractors
      const contractorsResponse = await axios.get(`${API_ENDPOINT}/api/contractors`, { params: { userId } });
      const contractorsData = contractorsResponse.data;

      // Fetch Jobs for the user
      const jobsResponse = await axios.get(`${API_ENDPOINT}/api/jobs`, { params: { userId } });
      const jobsData = jobsResponse.data;

      // Fetch Floorplans, Floors, and Rooms for each job
      for (let job of jobsData) {
        if (job.FloorPlanID) {
          const floorplanResponse = await axios.get(`${API_ENDPOINT}/api/floorplans/${job.FloorPlanID}`);
          job.floorplan = floorplanResponse.data;

          // Fetch Floors for each Floorplan
          for (let floorId of job.floorplan.Floors) {
            const floorResponse = await axios.get(`${API_ENDPOINT}/api/floors/${floorId}`);
            const floor = floorResponse.data;

            // Fetch Rooms for each Floor
            const roomsResponse = await axios.get(`${API_ENDPOINT}/api/rooms`);
            floor.rooms = roomsResponse.data.filter(room => floor.rooms.includes(room._id));

            job.floorplan.floors = job.floorplan.floors || [];
            job.floorplan.floors.push(floor);
          }
        }
      }

      // Structure the data
      const appData = {
        account: accountData,
        clients: clientsData,
        contractors: contractorsData,
        jobs: jobsData
      };

      // Store in localStorage
      localStorage.setItem('appData', JSON.stringify(appData));

      // Initialize AppState
      const appState = {
        userId,
        currentClientId: null,
        currentContractorId: null,
        currentJobId: null,
        currentFloorplanId: null,
        currentFloorId: null
      };

      localStorage.setItem('appState', JSON.stringify(appState));

      console.log('App data initialized successfully');
    } catch (error) {
      console.error('Error initializing app data:', error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${API_ENDPOINT}/api/auth/login`, data);
      console.log('Server Response:', res.data);
      const userId = res.data.userId;

      if (!userId) {
        console.error('userId is missing from response');
        return;
      }

      // Set token and userId cookie
      setToken(res.data.token);
      document.cookie = `userId=${userId}; path=/; max-age=${72 * 60 * 60}; secure=${process.env.NODE_ENV === 'production'};`;

      await initializeAppData(userId);
      navigate('/dashboard');
    } catch (err) {
      console.error('Login error:', err.message);
    }
  };
  
  function generateImageArray(count) {
    const images = [];
    for (let i = 1; i <= count; i++) {
      const imageNumber = i.toString().padStart(4, '0'); // Ensures the number is zero-padded to 4 digits
      images.push(`login_screen_${imageNumber}.webp`);
    }
    return images;
  }
  
  // Generate an array of 100 image file names
  const images = generateImageArray(38);
  
  // Function to get a random image
  function getRandomImage() {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
  }

  const randomImage = getRandomImage();

  return (
    <Box sx={{ flexGrow: 1, height: '100vh' }}>
      {palette && ( // Ensure palette is not null before rendering
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={7.2} sx={{ 
            bgcolor: palette.dominant,
            position: 'relative',
            overflow: 'hidden',
          }}>
            <img 
              src={`/images/login/${randomImage}`} 
              alt="Welcome to PaintQuotePro" 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            <Box sx={{ 
              position: 'absolute',
              bottom: '10%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '90%',
              zIndex: 1,
              bgcolor: 'rgba(0, 0, 0, 0.3)',
              borderRadius: '8px',
              padding: '10px',
              boxShadow: '0 0 0 0px white',
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
                {[1, 2, 3, 4, 5].map((num) => (
                  <Box 
                    key={num}
                    sx={{ 
                      width: 125, 
                      height: 75, 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <img 
                      src={`/images/logos/logo_${num}.webp`}
                      alt={`Paint Company Logo ${num}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3.6} sx={{ bgcolor: palette.secondary }}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Paper elevation={0} sx={{ p: 4, width: '60%', maxWidth: 300, bgcolor: 'white' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                  Login
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Email is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        margin="normal"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Password is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type="password"
                        fullWidth
                        margin="normal"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ 
                      mt: 3, 
                      bgcolor: palette.accent, 
                      '&:hover': { 
                        bgcolor: palette.accent,
                        filter: 'brightness(90%)'
                      } 
                    }}
                  >
                    Login
                  </Button>
                </form>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={1.2} sx={{ bgcolor: palette.accent }} />
        </Grid>
      )}
    </Box>
  );
};

export default Login;