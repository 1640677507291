import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { PictureAsPdf, Email } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './QuoteTable.css';

const QuoteTable = ({ logo, clientFullName, clientEmail, clientPhone, clientStreetAddress, clientCity, clientState, clientPostalCode, clientCountry, quotes, totals }) => {

  const handleDownloadPDF = () => {
    const quoteElement = document.getElementById('quote-container');
    html2canvas(quoteElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('quote.pdf');
    });
  };

  const handleEmailPDF = () => {
    const quoteElement = document.getElementById('quote-container');
    html2canvas(quoteElement, {
      useCORS: true,
      allowTaint: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        const mailtoLink = `mailto:${clientEmail}?subject=Quote&body=Please find attached the quote.&attachment=${base64data}`;
        window.location.href = mailtoLink;
      };
    });
  };

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="flex-start" mb={2}>
        <IconButton onClick={handleDownloadPDF} sx={{ fontSize: 40 }}>
          <PictureAsPdf fontSize="inherit" />
        </IconButton>
        <IconButton onClick={handleEmailPDF} sx={{ fontSize: 40 }}>
          <Email fontSize="inherit" />
        </IconButton>
      </Box>

      <Box className="quote-container" sx={{ transform: 'scale(0.45)', transformOrigin: 'top left', backgroundColor: 'white', padding: 2, borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <Box className="quote" id="quote-container">
          <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box className="logo" ml={2}>
                {logo ? (
                  <img src={logo} alt="Company Logo" className="company-logo" />
                ) : (
                  <Typography variant="h1" className="quote-title">{logo}</Typography>
                )}
              </Box>
            </Box>
            <Box className="quote-details" textAlign="right">
              <Typography variant="h2" className="quote-header">QUOTE</Typography>
              <Typography>Date: 05.01.2025</Typography>
              <Typography>Quote No: #001</Typography>
            </Box>
          </Box>

          <Box className="bill-to" my={2}>
            <Typography>Bill To:</Typography>
            <Typography><strong>{clientFullName}</strong></Typography>
            <Typography>{clientStreetAddress}</Typography>
            <Typography>{clientCity}, {clientState}</Typography>
            <Typography>{clientPostalCode} {clientCountry}</Typography>
          </Box>

          <Typography variant="h6" sx={{ margin: '16px 0' }}>
            Quote Summary
          </Typography>

          <Box component="table" className="quote-table" width="70%" bordercollapse="collapse" mb={2}>
            <Box component="thead">
              <tr>
                <th className="table-header">Room Name</th>
                {/* <th className="table-header">Width (m)</th>
                <th className="table-header">Length (m)</th> */}
                {/* <th className="table-header">Floor Area (m²)</th> */}
                <th className="table-header">Wall Area (m²)</th>
                {/* <th className="table-header">Painting Hours</th> */}
                <th className="table-header">Painting Cost</th>
                {/* <th className="table-header">Paint Required (L)</th> */}
                <th className="table-header">Paint Cost</th>
                <th className="table-header">Prep Cost</th>
                <th className="table-header">Woodwork Cost</th>
                <th className="table-header">Total Cost</th>
              </tr>
            </Box>
            <Box component="tbody">
              {quotes.map((quote) => (
                <tr key={quote.id}>
                  <td className="table-cell">{quote.name}</td>
                  {/* <td className="table-cell">{quote.widthInMeters}</td>
                  <td className="table-cell">{quote.lengthInMeters}</td> */}
                  {/* <td className="table-cell">{quote.floorArea}</td> */}
                  <td className="table-cell">{quote.wallArea}</td>
                  {/* <td className="table-cell">{quote.paintingHours}</td> */}
                  <td className="table-cell">{quote.paintingCost}</td>
                  {/* <td className="table-cell">{quote.paintRequired}</td> */}
                  <td className="table-cell">{quote.paintCost}</td>
                  <td className="table-cell">{quote.prepCost}</td>
                  <td className="table-cell">{quote.skirtingArchitraveCost}</td>
                  <td className="table-cell">{quote.totalCost}</td>
                </tr>
              ))}
              <tr>
                <td className="table-cell"><strong>Total</strong></td>
                {/* <td className="table-cell"></td>
                <td className="table-cell"></td> */}
                {/* <td className="table-cell">{totals.totalFloorArea}</td> */}
                <td className="table-cell">{totals.totalWallArea}</td>
                {/* <td className="table-cell">{totals.totalPaintingHours}</td> */}
                <td className="table-cell">{totals.totalPaintingCost}</td>
                {/* <td className="table-cell">{totals.totalPaintRequired}</td> */}
                <td className="table-cell">{totals.totalPaintCost}</td>
                <td className="table-cell">{totals.totalPrepCost}</td>
                <td className="table-cell">{totals.totalSkirtingArchitraveCost}</td>
                <td className="table-cell">{totals.totalCost}</td>
              </tr>
            </Box>
          </Box>

          <Box className="footer" display="flex" justifyContent="space-between">
            <Box className="thank-you">
              <Typography variant="h2" className="thank-you-text">THANK YOU!</Typography>
            </Box>
            <Box className="payment-method">
              <Typography><strong>PAYMENT METHOD</strong></Typography>
              <Typography>Payment: VISA</Typography>
              <Typography>Paypal: jito@email.com</Typography>
            </Box>
            <Box className="contact">
              <Typography><strong>CONTACT</strong></Typography>
              <Typography>103 Main St, Portland OR</Typography>
              <Typography>jito@email.com</Typography>
              <Typography>1.800.555.4321</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuoteTable;
