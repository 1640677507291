import React, { useRef, useState } from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import { PictureAsPdf, Email } from '@mui/icons-material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './Inspection.css';

const Inspection = ({
  logo,
  clientFullName,
  clientEmail,
  clientPhone,
  clientStreetAddress,
  clientCity,
  clientState,
  clientPostalCode,
  clientCountry,
  numInvoices,
  inspectionReport
}) => {
  const reportRefs = useRef([]);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const generatePDF = async (invoiceNumber, callback) => {
    setIsGeneratingPDF(true);
    const invoiceElement = reportRefs.current[invoiceNumber - 1];
    if (!invoiceElement) {
      setIsGeneratingPDF(false);
      return;
    }

    try {
      // Use html2canvas to render the element, including images
      const canvas = await html2canvas(invoiceElement, {
        scale: 1,
        useCORS: true, // Enable CORS for images from different origins
        allowTaint: true, // Allow cross-origin images to taint the canvas
        logging: true, // Enable logging for debugging
        onclone: (clonedDoc) => {
          // Optional: Adjust image styles in the cloned document if needed
          const clonedImages = clonedDoc.querySelectorAll('img');
          clonedImages.forEach(img => {
            img.style.maxWidth = '100%';
            img.style.height = 'auto';
          });
        }
      });
      
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 0;

      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      callback(pdf);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  const handleDownloadPDF = (invoiceNumber) => {
    generatePDF(invoiceNumber, (pdf) => {
      pdf.save(`invoice-${invoiceNumber}.pdf`);
    });
  };

  const handleEmailPDF = (invoiceNumber) => {
    generatePDF(invoiceNumber, (pdf) => {
      const pdfBlob = pdf.output('blob');
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        // Instead of using `mailto:`, which has limitations, 
        // consider using a server-side solution to send the email 
        // with the PDF attachment.
        console.log("PDF Data:", base64data); 
      };
    });
  };

  // Placeholder data if inspectionReport is null or undefined
  const placeholderReport = {
    room_overview: { type: 'N/A', condition: 'N/A' },
    walls: { condition: 'N/A', paint_finish: 'N/A', preparation_required: 'N/A' },
    ceiling: { condition: 'N/A', ceiling_features: 'N/A', preparation_required: 'N/A' },
    woodwork_and_trim: { condition: 'N/A', preparation_required: 'N/A' },
    windows_and_doors: { condition: 'N/A', preparation_required: 'N/A' },
    flooring: { condition: 'N/A', preparation_required: 'N/A' },
    furniture_and_obstructions: { none: 'N/A' },
    special_considerations: { mold_and_water_damage: 'N/A', structural_issues: 'N/A' },
    summary: 'N/A',
  };

  const report = inspectionReport || placeholderReport;

  const styles = {
    container: {
      padding: '8px',
      fontSize: '12px',
      margin: '16px',
    },
    logo: {
      width: '160px',
      height: 'auto',
    },
    invoiceTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    invoiceHeader: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    reportContent: {
      marginBottom: '12px',
      fontSize: '14px',
    },
    sectionTitle: {
      marginTop: '12px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    thankYouText: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    margins: {
      margin: '8px',
    },
  };

  return (
    <Box sx={styles.container}>
      {Array.from({ length: numInvoices }, (_, index) => (
        <Box key={index} sx={styles.margins}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <IconButton 
              onClick={() => handleDownloadPDF(index + 1)} 
              sx={{ fontSize: 30 }}
              disabled={isGeneratingPDF}
            >
              <PictureAsPdf fontSize="inherit" />
            </IconButton>
            <IconButton 
              onClick={() => handleEmailPDF(index + 1)} 
              sx={{ fontSize: 30 }}
              disabled={isGeneratingPDF}
            >
              <Email fontSize="inherit" />
            </IconButton>
            {isGeneratingPDF && <CircularProgress size={24} sx={{ ml: 2 }} />}
          </Box>
          <Box 
            className="report-container" 
            ref={el => reportRefs.current[index] = el}
          >
            <Box className="report" id={`invoice-${index + 1}`}>
              <Box className="header" display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                  <Box className="logo" ml={2}>
                    {logo ? (
                      <img src={logo} alt="Company Logo" style={styles.logo} />
                    ) : (
                      <Typography variant="h1" style={styles.invoiceTitle}>{logo}</Typography>
                    )}
                  </Box>
                </Box>
                <Box className="invoice-details" textAlign="right">
                  <Typography style={styles.invoiceHeader}>INSPECTION REPORT</Typography>
                  <Typography>No. #{index + 1}</Typography>
                  <Typography>Date: {new Date().toLocaleDateString()}</Typography>
                </Box>
              </Box>

              <Box component="div" className="report-content" sx={styles.reportContent}>
                <Typography variant="h6" style={styles.sectionTitle}>Summary</Typography>
                <Typography style={styles.reportContent}>{report.summary}</Typography>

                <Typography style={styles.sectionTitle}>Room Overview</Typography>
                <Typography style={styles.reportContent}>{report.room_overview.type} {report.room_overview.condition}</Typography>
                
                <Typography style={styles.sectionTitle}>Walls</Typography>
                <Typography style={styles.reportContent}>{report.walls.condition} {report.walls.paint_finish}</Typography>
                <Typography style={styles.reportContent}>{report.walls.preparation_required}</Typography>

                <Typography style={styles.sectionTitle}>Ceiling</Typography>
                <Typography style={styles.reportContent}>{report.ceiling.condition} {report.ceiling.ceiling_features}</Typography>
                <Typography style={styles.reportContent}>{report.ceiling.preparation_required}</Typography>

                <Typography style={styles.sectionTitle}>Woodwork and Trim</Typography>
                <Typography style={styles.reportContent}>{report.woodwork_and_trim.condition} {report.woodwork_and_trim.preparation_required}</Typography>
                
                <Typography style={styles.sectionTitle}>Windows and Doors</Typography>
                <Typography style={styles.reportContent}>{report.windows_and_doors.condition} {report.windows_and_doors.preparation_required}</Typography>
                
                <Typography style={styles.sectionTitle}>Flooring</Typography>
                <Typography style={styles.reportContent}>{report.flooring.condition}</Typography>
                <Typography style={styles.reportContent}>{report.flooring.preparation_required}</Typography>

                <Typography style={styles.sectionTitle}>Furniture and Obstructions</Typography>
                <Typography style={styles.reportContent}>{report.furniture_and_obstructions.none}</Typography>

                <Typography style={styles.sectionTitle}>Special Considerations</Typography>
                <Typography style={styles.reportContent}><strong>Mold and Water Damage:</strong> {report.special_considerations.mold_and_water_damage}</Typography>
                <Typography style={styles.reportContent}><strong>Structural Issues:</strong> {report.special_considerations.structural_issues}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Inspection;