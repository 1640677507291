import React from 'react';
import { Box, Typography, Tooltip, tooltipClasses } from '@mui/material';
import { CropDin, SelectAll, CropFree, CallToAction, DoorFront } from '@mui/icons-material';
import { styled } from '@mui/system';
import CustomSlider from './CustomSlider';
import { CustomToggleButton, CustomToggleButtonGroup } from './CustomToggleButton';
import theme from './theme'; // Import the theme

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '18px',
    padding: '8px',
  },
});

const PaintOptions = ({
  style,
  setStyle,
  coverage,
  setCoverage,
  metersSquared,
  setMetersSquared,
  roomHeight,
  setRoomHeight,
  wallCoats,
  setWallCoats,
  roomPrep,
  setRoomPrep,
  color,
  setColor,
}) => {
  const handleChange = (setter, multiple = false) => (event, value) => {
    if (multiple) {
      // Handle multiple selections
      setter(value);
    } else {
      setter(value);
    }
  };

  return (
    <Box p={0} sx={{ ...theme.outerBox, ...theme.blurredBackground }}>
     
      <Box my={0}>
        <CustomToggleButtonGroup
          value={style}
          onChange={handleChange(setStyle, true)}
          fullWidth
          multiple
        >
          <CustomTooltip title="Walls Only">
            <CustomToggleButton value="pattern"><CropDin /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Ceiling">
            <CustomToggleButton value="grid"><SelectAll /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Skirting/Archetrave">
            <CustomToggleButton value="slant"><CropFree /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Doors">
            <CustomToggleButton value="doors"><DoorFront /></CustomToggleButton>
          </CustomTooltip>
          <CustomTooltip title=" + Windows">
            <CustomToggleButton value="gradient"><CallToAction /></CustomToggleButton>
          </CustomTooltip>
        </CustomToggleButtonGroup>
      </Box>
      <Box sx={theme.innerBox}>
      <Box sx={{ mb: 3, border: '1px solid #fff', borderRadius: '8px', p: 2, backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Typography variant="subtitle1" sx={theme.customToggleButton}>Paint Coverage</Typography>
      <CustomSlider
        value={coverage}
        onChange={handleChange(setCoverage)}
        valueLabelDisplay="auto"
        min={10}
        max={20}
      />
      <Typography variant="subtitle1" sx={theme.customToggleButton}>Productivity (m²/hour)</Typography>
      <CustomSlider
        value={metersSquared}
        onChange={handleChange(setMetersSquared)}
        valueLabelDisplay="auto"
        min={10}
        max={20}
      />
      <Typography variant="subtitle1"  sx={theme.customToggleButton}>Room Height</Typography>
      <CustomSlider
        value={roomHeight}
        onChange={handleChange(setRoomHeight)}
        valueLabelDisplay="auto"
        min={2.5}
        max={7.0}
        step={0.1}
      />
      <Box my={2}>
        <Typography variant="subtitle1"  sx={theme.customToggleButton}>Wall Coats</Typography>
        <CustomToggleButtonGroup
          value={wallCoats}
          exclusive
          onChange={handleChange(setWallCoats)}
          fullWidth
        >
          <CustomToggleButton value="1" sx={theme.customToggleButton}>One</CustomToggleButton>
          <CustomToggleButton value="2" sx={theme.customToggleButton}>Two</CustomToggleButton>
          <CustomToggleButton value="3" sx={theme.customToggleButton}>Three</CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      <Box my={2}>
        <Typography variant="subtitle1" sx={theme.customToggleButton}>Room Prep</Typography>
        <CustomToggleButtonGroup
          value={roomPrep}
          exclusive
          onChange={handleChange(setRoomPrep)}
          fullWidth
          > 
          <CustomToggleButton value="low" sx={theme.customToggleButton}>Low</CustomToggleButton>
          <CustomToggleButton value="medium" sx={theme.customToggleButton}>Med</CustomToggleButton>
          <CustomToggleButton value="high"  sx={theme.customToggleButton}>High</CustomToggleButton>
        </CustomToggleButtonGroup>
      </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default PaintOptions;
