const theme = {

  typography: {
    fontFamily: '"Figtree", sans-serif', // Set your default font here
    fontSize: 36,
    fontWeightBold: 700,
  },

  tabs: {
    root: {
      backgroundColor: 'rgb(235,235,235)', // Background color of the Tabs container
    },
    tab: {
      base: {
        textTransform: 'none', // Prevents text from being all uppercase
        fontWeight: 'normal',
        fontSize: '1.0rem',
        fontFamily: '"Figtree", sans-serif',
        color: '#000', // Normal text color
        '&:hover': {
          transition: 'background-color 1s',
          color: '#fff', // Text color on hover
          fontWeight: 'bold',
          backgroundColor: '#007bff'
        },
      },
      selected: {
        textTransform: 'UPPERCASE',
        color: '#28a745', // Text color when selected
        fontWeight: 'bold', // Font weight when selected
        fontSize: '1.0rem',
        backgroundColor: '#fff'
      },
    },
  },  
  

  outerBox: {
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '10px solid #fff',
    borderRadius: '100px',
    backgroundColor: 'rgba(0, 0, 0, 1)',
    padding: 0,
  },
  innerBox: {
    borderRadius: '10px',
    padding: '8px',
    margin: '8px',
  },
  sliderLabel: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 5px',
    color: 'black',
  },
  inputStyle: {
    margin: '10px 0',
    '& .MuiInputBase-root': {
      height: '40px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.0rem',
      color: 'black',
    },
    '& .MuiInputBase-input': {
      fontSize: '1.0rem',
      color: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
  successButton: {
    backgroundColor: 'green',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurredBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)', // For Safari support
    border: '0px solid #fff',
  },
  canvas: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
    position: 'relative',
    outlineStyle: 'dashed',
    outlineColor: 'rgba(0, 0, 0, 0.3)',
    maxWidth: '100%',
  },
  uploadButton: {
    margin: '10px 0',
    backgroundColor: '#007bff',
    color: 'white',
    '&:hover': {
      backgroundColor: '#28a745',
    },
  },
  positionedActionButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    '&:hover': {
      backgroundColor: '#28a745',
    },
  },
  customTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    border: '2px solid white',
    borderRadius: '8px',
    fontSize: '18px',
    padding: '8px',
  },
  customToggleButton: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 5px',
    color: 'black',
  },
  // New styles for spaced toggle button group
  spacedCustomToggleButtonGroup: {
    '& .MuiToggleButtonGroup-grouped': {
      margin: '14px', // You can adjust this value to increase or decrease spacing
      border: 0,
      '&:not(:first-of-type)': {
        borderRadius: '4px', // Adjust this value to match your desired border radius
      },
      '&:first-of-type': {
        borderRadius: '4px', // Adjust this value to match your desired border radius
      },
    },
  },
  linedCustomToggleButtonGroup: {
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      '&:not(:last-of-type)': {
        borderRight: '4px solid rgba(0, 0, 0, 1)', // You can adjust the color to match your design
      },
    },
  },
  centeredOverlayBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    backdropFilter: 'blur(100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    zIndex: 1000,
  },
  // New styles for ListItem
  listItem: {
    cursor: 'pointer', 
    transition: 'background-color 0.5s', 
    // borderTopLeftRadius: '32px', 
    // borderBottomLeftRadius: '32px', 
    '&:hover': {
      backgroundColor: 'lightblue', 
    },
  },
  listItemTextName: {
    color: '#000', 
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: '"Figtree", sans-serif', // Set your default font here
    '&:hover': {
      color: '#007bff', 
    },
  },
  listItemText: {
    color: '#666', // Text color
    fontSize: '14px',
    fontWeight: 'normal',
    fontFamily: '"Figtree", sans-serif',
    '&:hover': {
      color: '#007bff', // Change text color on hover
    },
  },

  customFormBox: {
    mb: 1,
    border: '1px solid rgba(0, 0, 0, 0)',
    borderRadius: '8px 8px 0 0',
    p: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  },
  customComponents: {
    StyledFormControl: {
      minWidth: '120px',
      marginRight: '16px',
      height: '40px',
      '& .MuiInputLabel-root': {
        fontSize: '0.9rem',
        fontFamily: '"Figtree", sans-serif',
        color: '#333',
      },
      '& .MuiSelect-root': {
        fontSize: '0.9rem',
        fontFamily: '"Figtree", sans-serif',
        color: '#000',
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        '& fieldset': {
          borderColor: '#007bff',
        },
        '&:hover fieldset': {
          borderColor: '#0056b3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#28a745',
        },
      },
    },
    StyledTextField: {
      minWidth: '200px',
      height: '40px',
      '& .MuiInputBase-root': {
        fontFamily: '"Figtree", sans-serif',
        fontSize: '0.9rem',
        color: '#000',
      },
      '& .MuiInputLabel-root': {
        fontFamily: '"Figtree", sans-serif',
        fontSize: '0.9rem',
        color: '#333',
      },
      '& .MuiOutlinedInput-root': {
        height: '100%',
        '& fieldset': {
          borderColor: '#007bff',
        },
        '&:hover fieldset': {
          borderColor: '#0056b3',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#28a745',
        },
      },
    },
    SwatchBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '5px',
      borderRadius: '3px',
      backgroundColor: '#fff',
      margin: '15px 5px',
      dropshadow: '0px 0px 20px rgba(0, 0, 0, 1)',
    },
    SwatchColorBox: {
      width: '150px',
      height: '175px',
      borderRadius: '3px',
      border: '0px solid #333',
      marginBottom: '5px',
      position: 'relative',
    },
    SwatchHex: {
      position: 'absolute',
      bottom: '5px',
      left: '10px',
      fontFamily: '"Figtree", sans-serif',
      fontSize: '14px',
    },
    SwatchName: {
      marginTop: '5px',
      fontFamily: '"Figtree", sans-serif',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#222',
    },
  },

};

export default theme;